// Remove entire file as part of BNCASB-5258
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { UpdateDataTable } from 'global/types/dataTables/dataTables'

import { DataTableState, ColumnsConfig, SortFields, GridColumns } from 'sen/redux/types/dataTables'

export const GRID_COLUMNS: GridColumns = {
  FAILED_REPORTS: 'failed_reports',
  PASSED_REPORTS: 'passed_reports',
  SPF_STATUS: 'spf_status',
  SPF_DOMAIN: 'spf_domain',
  DKIM_STATUS: 'dkim_status',
  DKIM_DOMAIN: 'dkim_domain',
  ACTIONS: 'actions'
}

export const SORT_FIELDS: SortFields = {}

export const COLUMNS_CONFIG: ColumnsConfig = {
  [GRID_COLUMNS.FAILED_REPORTS]: {
    sortable: false,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.PASSED_REPORTS]: {
    sortable: false,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.SPF_STATUS]: {
    sortable: false,
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.SPF_DOMAIN]: {
    sortable: false,
    orderIndex: 2,
    show: true
  },
  [GRID_COLUMNS.DKIM_STATUS]: {
    sortable: false,
    orderIndex: 3,
    show: true
  },
  [GRID_COLUMNS.DKIM_DOMAIN]: {
    sortable: false,
    orderIndex: 4,
    show: true
  },
  [GRID_COLUMNS.ACTIONS]: {
    sortable: false,
    orderIndex: 5,
    show: true
  }
}

const ITEMS_PER_PAGE = 5

// Store
export const INITIAL_STATE: DataTableState = {
  SORT_FIELDS,
  GRID_COLUMNS,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: null,
  search: '',
  sort: []
}

export default function createDmarcSubdomnainsStore(tableName: string) {
  return createSlice({
    name: `DATA_TABLE/DMARC_SUBDOMAINS/${tableName}`,
    initialState: INITIAL_STATE,
    reducers: {
      update: {
        reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) => {
          return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
        },
        prepare: (config: UpdateDataTable) => ({
          payload: { config }
        })
      },
      reset: () => {
        return {
          ...INITIAL_STATE
        }
      }
    }
  })
}
