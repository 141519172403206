import { useEffect, useCallback, useReducer, useMemo } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import * as cloudLib from 'global/lib/cloud/cloud'
import { config } from 'global/lib/config'
import { setError, reset } from 'global/redux/features/auth/authSlice'
import { resetLoggedOut } from 'global/redux/features/app/appSlice'
import { User } from 'global/types/api/userType'

import gotoReportPage from 'dfp/lib/gotoReportPage'
import { useAppDispatch, useAppSelector } from 'dfp/redux/toolkit/hooks'

export interface SigninSignupLogicProps {
  error: string | false
  isLoading: boolean
  isPermissionDialogVisible: boolean
  onSignin: () => void
  onStartTrial: () => void
  onTogglePermissionDialog: () => void
  requestDemoPath: string
  salesLeadEpId: number
  sendDemoRequestTrackingEvent?: () => void
}

declare global {
  interface Window {
    __completeBccSignin__: (user: User) => void
    __failedBccSignin__: (error: any) => void
  }
}

export default function useSignupLogic(): [SigninSignupLogicProps] {
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    isPermssionEventSent: false,
    isPermissionDialogVisible: false,
    isLoading: false
  })
  const { auth } = useAppSelector(_stores => ({
    auth: _stores.auth
  }))
  const dispatch = useAppDispatch()

  // Init
  useEffect(() => {
    dispatch(reset())
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_PAGEVIEW, { url: window.location.href })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // signin flow
  const onCompleteBccSignin = useCallback((user: User) => {
    setState({ isLoading: true })
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_SIGNIN_SUCCESSFUL, {
      url: window.location.href,
      email: user.email
    })

    gotoReportPage({ user })
  }, [])

  const onFailedBccSignin = useCallback(
    (data: any) => {
      const errorMessage = data.error.includes('UserDoesNotExist') ? 'user_does_not_exist' : 'default'
      dispatch(setError(errorMessage))
    },
    [dispatch]
  )

  const onSignin = useCallback(() => {
    dispatch(resetLoggedOut())

    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNIN_LOGIN, { url: window.location.href })

    /* eslint-disable no-underscore-dangle */
    window.__completeBccSignin__ = onCompleteBccSignin
    window.__failedBccSignin__ = onFailedBccSignin
    /* eslint-enable no-underscore-dangle */

    cloudLib.bccSignin(config.SCAN_TYPES.DFP)
  }, [dispatch, onCompleteBccSignin, onFailedBccSignin])

  // start trial
  const onStartTrial = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.FREE_TRIAL_STARTED, {
      page: 'signup',
      product: config.PRODUCTS.DFP,
      url: window.location.href
    })
  }, [])

  const onTogglePermissionDialog = useCallback(() => {
    if (!state.isPermssionEventSent) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_PERMISSION, { url: window.location.href })
    }
    setState({ isPermissionDialogVisible: !state.isPermissionDialogVisible, isPermssionEventSent: true })
  }, [state.isPermissionDialogVisible, setState, state.isPermssionEventSent])

  const sendDemoRequestTrackingEvent = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_REQUEST_DEMO, { url: window.location.href })
  }, [])

  return useMemo(() => {
    return [
      {
        error: auth.error || false,
        isLoading: state.isLoading,
        isPermissionDialogVisible: state.isPermissionDialogVisible,
        onSignin,
        onStartTrial,
        onTogglePermissionDialog,
        requestDemoPath: config.LINKS.REQUEST_A_DEMO,
        salesLeadEpId: config.PRODUCTS.IDS.DFP,
        sendDemoRequestTrackingEvent
      }
    ]
  }, [
    auth.error,
    state.isLoading,
    state.isPermissionDialogVisible,
    onSignin,
    onStartTrial,
    onTogglePermissionDialog,
    sendDemoRequestTrackingEvent
  ])
}
