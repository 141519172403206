// Remove entire file as part of BNCASB-5258
import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError, ApiRejectResponse, ApiSuccessReportWithOffset } from 'global/lib/api/restClient'
import { buildReportQueryFor } from 'global/redux/features/dataTables/buildQueryForTable'
import {
  CompanyDomain,
  DmarcSourcesReport,
  DmarcSubdomainsReport,
  DmarcHostsReport,
  DomainStatus,
  SpfRecord,
  DmarcEntry,
  DomainsStats,
  DomainTotals,
  DmarcRufSamplesReport,
  RufSample,
  FraudUnifiedData,
  FraudUnifiedRufDataReport,
  DMARC_SENDER_APPROVAL_STATUSES
} from 'global/types/api/dmarc'

import apiRoutes from 'sen/lib/api/apiRoutes'
import { RootState } from 'sen/redux/toolkit/store'
import * as T from 'sen/redux/features/dmarc/dmarcTypes'
import { validateDmarcDkimPassingHostsResponse } from 'sen/redux/features/dmarc/dmarcValidator'

export const getCompanyDomains = createAsyncThunk<CompanyDomain[], undefined, ApiRejectResponse>(
  'DMARC/companyDomains',
  async function doGetCompanyDomains(_, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.DMARC_COMPANY_DOMAINS, {
        data: {}
      })

      return resp.data?.companyDomains
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getDomainTotals = createAsyncThunk<DomainTotals, T.GetDomainTotalsPayload, ApiRejectResponse>(
  'DMARC/getDomainTotals',
  async function doGetDomainTotals(payload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.DOMAIN_TOTALS, {
        data: { domain: payload }
      })

      return resp.data?.reportData
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getDomainsStats = createAsyncThunk<DomainsStats, T.GetDomainsStatsPayload, ApiRejectResponse>(
  'DMARC/getDomainsStats',
  async function doGetDomainsStats(payload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.DMARC_DOMAINS_STATS, {
        data: { domains: payload }
      })

      return resp.data?.reportData
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const resolveDmarc = createAsyncThunk<DmarcEntry, T.ResolveDmarcPayload, ApiRejectResponse>(
  'DMARC/resolveDmarc',
  async function doResolveDmarc(payload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.RESOLVE_DMARC, {
        data: { domain: payload }
      })

      return resp.data?.dmarcResult
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const resolveSpf = createAsyncThunk<SpfRecord, T.ResolveSpfPayload, ApiRejectResponse>(
  'DMARC/resolveSpf',
  async function doResolveSpf(payload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.RESOLVE_SPF, {
        data: { domain: payload }
      })

      return resp.data?.spfResult
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const setupDmarc = createAsyncThunk<undefined, T.SetupDmarcPayload, ApiRejectResponse>(
  'DMARC/setupDmarc',
  async function dosetupDmarc(payload, { rejectWithValue }) {
    try {
      await restClient(apiRoutes.SETUP_DMARC, {
        data: { domain: payload }
      })

      return undefined
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const addDomain = createAsyncThunk<DomainStatus, T.UpdateDomainPayload, ApiRejectResponse>(
  'DMARC/addDomain',
  async function doAddDomain(payload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.ADD_DOMAIN, {
        data: { domainName: payload }
      })

      return resp.data?.domain
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const verifyDomain = createAsyncThunk<DomainStatus, T.UpdateDomainPayload, ApiRejectResponse>(
  'DMARC/verifyDomain',
  async function doVerifyDomain(payload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.VERIFY_DOMAIN, {
        data: { domainName: payload }
      })

      return resp.data?.domain
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const deleteDomain = createAsyncThunk<DomainStatus, T.UpdateDomainPayload, ApiRejectResponse>(
  'DMARC/deleteDomain',
  async function doDeleteDomain(payload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.DELETE_DOMAIN, {
        data: { domainName: payload }
      })

      return resp.data?.domain
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getAllApprovedSources = createAsyncThunk<
  ApiSuccessReportWithOffset<DmarcSourcesReport>,
  T.GetDmarcSourcesPayload,
  ApiRejectResponse
>('DMARC/getAllApprovedSources', async function doGetAllApprovedSources(payload, { rejectWithValue, getState }) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.dmarcSources.allApproved, true)

    const resp = await restClient(apiRoutes.ALL_DMARC_SENDERS, {
      data: { domain: payload.domain, status: DMARC_SENDER_APPROVAL_STATUSES.approved, query }
    })

    return { report: resp.data?.hosts, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getAllRejectedSources = createAsyncThunk<
  ApiSuccessReportWithOffset<DmarcSourcesReport>,
  T.GetDmarcSourcesPayload,
  ApiRejectResponse
>('DMARC/getAllRejectedSources', async function doGetAllRejectedSources(payload, { rejectWithValue, getState }) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.dmarcSources.allRejected, true)

    const resp = await restClient(apiRoutes.ALL_DMARC_SENDERS, {
      data: { domain: payload.domain, status: DMARC_SENDER_APPROVAL_STATUSES.rejected, query }
    })

    return { report: resp.data?.hosts, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getDmarcLowVolumeSources = createAsyncThunk<
  ApiSuccessReportWithOffset<DmarcSourcesReport>,
  T.GetDmarcVolumeSourcesPayload,
  ApiRejectResponse
>('DMARC/getDmarcLowVolumeSources', async function doGetDmarcLowVolumeSources(payload, { rejectWithValue, getState }) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.dmarcSources.lowVolume, true)

    const resp = await restClient(apiRoutes.MINOR_DMARC_SENDERS, {
      data: { domain: payload.domain, status: payload.status, query }
    })

    return { report: resp.data?.hosts, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getDmarcHighVolumeSources = createAsyncThunk<
  ApiSuccessReportWithOffset<DmarcSourcesReport>,
  T.GetDmarcVolumeSourcesPayload,
  ApiRejectResponse
>('DMARC/getDmarcHighVolumeSources', async function doGetDmarcHighVolumeSources(
  payload,
  { rejectWithValue, getState }
) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.dmarcSources.highVolume, true)

    const resp = await restClient(apiRoutes.MAJOR_DMARC_SENDERS, {
      data: { domain: payload.domain, status: payload.status, query }
    })

    return { report: resp.data?.hosts, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const setApprovalStatusForSender = createAsyncThunk<
  T.SetApprovalStatusForSenderPayload,
  T.SetApprovalStatusForSenderPayload,
  ApiRejectResponse
>('DMARC/setApprovalStatusForSender', async function doSetApprovalStatusForSender(payload, { rejectWithValue }) {
  try {
    await restClient(apiRoutes.APPROVE_ACTIONS_SET_FOR_SENDER, {
      data: { domain: payload.domain, status: payload.status, sender: payload.sender }
    })

    return payload
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const setApprovalStatusForVolumeGroup = createAsyncThunk<
  T.SetApprovalStatusForVolumeGroupPayload,
  T.SetApprovalStatusForVolumeGroupPayload,
  ApiRejectResponse
>('DMARC/setApprovalStatusForVolumeGroup', async function doSetApprovalStatusForVolumeGroup(
  payload,
  { rejectWithValue }
) {
  try {
    await restClient(apiRoutes.APPROVE_ACTIONS_SET_FOR_VOLUME_GROUP, {
      data: { domain: payload.domain, status: payload.status, volume: payload.volume }
    })

    return payload
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getDmarcDkimPassingHosts = createAsyncThunk<
  DmarcHostsReport,
  T.GetDmarcDkimPassingHostsPayload,
  ApiRejectResponse
>('DMARC/getDmarcDkimPassingHosts', async function doGetDmarcDkimPassingHosts(payload, { rejectWithValue }) {
  try {
    const resp = await restClient(apiRoutes.GET_DMARC_DKIM_PASSING_HOSTS, {
      data: payload
    })

    return validateDmarcDkimPassingHostsResponse(resp.data).hosts
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getDmarcFailedSubdomains = createAsyncThunk<
  ApiSuccessReportWithOffset<DmarcSubdomainsReport>,
  T.GetDmarcSubdomainsPayload,
  ApiRejectResponse
>('DMARC/getDmarcFailedSubdomains', async function doGetDmarcFailedSubdomains(payload, { rejectWithValue, getState }) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.dmarcSubdomains.failed, true)
    const resp = await restClient(apiRoutes.SUBDOMAIN_SENDERS, {
      data: { ...payload, isFail: true, query }
    })

    return { report: resp.data?.subdomains, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getDmarcPassedSubdomains = createAsyncThunk<
  ApiSuccessReportWithOffset<DmarcSubdomainsReport>,
  T.GetDmarcSubdomainsPayload,
  ApiRejectResponse
>('DMARC/getDmarcPassedSubdomains', async function doGetDmarcPassedSubdomains(payload, { rejectWithValue, getState }) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.dmarcSubdomains.passed, true)
    const resp = await restClient(apiRoutes.SUBDOMAIN_SENDERS, {
      data: { ...payload, isFail: false, query }
    })

    return { report: resp.data?.subdomains, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getRufSample = createAsyncThunk<RufSample, T.GetRufSamplePayload, ApiRejectResponse>(
  'DMARC/getRufSample',
  async function doGetRufSample(payload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.GET_RUF_SAMPLE, {
        data: { ...payload }
      })

      return resp.data.sample
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getRufSamples = createAsyncThunk<
  ApiSuccessReportWithOffset<DmarcRufSamplesReport>,
  T.GetDmarcSubdomainsPayload,
  ApiRejectResponse
>('DMARC/getRufSamples', async function doGetRufSamples(payload, { rejectWithValue, getState }) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.dmarcSamples.ruf, true)
    const resp = await restClient(apiRoutes.RUF_SAMPLES, {
      data: { ...payload, query }
    })

    return { report: resp.data?.samples, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getDmarcFraudUnifiedData = createAsyncThunk<
  FraudUnifiedData[],
  T.GetDmarcFraudUnifiedDataPayload,
  ApiRejectResponse
>('DMARC/getDmarcFraudUnifiedData', async function doGetDmarcFraudUnifiedData(payload, { rejectWithValue }) {
  try {
    const query = { page: 1, limit: 500 }

    const resp = await restClient(apiRoutes.GET_DMARC_FRAUD_UNIFIED_DATA, {
      data: { domain: payload.domain, query }
    })

    return resp.data?.results
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getDmarcFraudUnifiedRufData = createAsyncThunk<
  ApiSuccessReportWithOffset<FraudUnifiedRufDataReport>,
  T.GetDmarcFraudUnifiedRufDataPayload,
  ApiRejectResponse
>('DMARC/getDmarcFraudUnifiedRufData', async function doGetDmarcFraudUnifiedRufData(
  payload,
  { rejectWithValue, getState }
) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.dmarcFraudUnifiedRufData)

    const resp = await restClient(apiRoutes.GET_DMARC_FRAUD_UNIFIED_RUF_DATA, {
      data: { domain: payload.domain, country: payload.country, query }
    })

    return { report: resp.data?.rufData, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
