import { createSlice, AnyAction, PayloadAction } from '@reduxjs/toolkit'

import { User } from 'global/types/api/userType'
import {
  setSuccessCallbacks,
  setFailedCallbacks,
  signin,
  signinOtp,
  signup,
  newSignup,
  domainFraudSignup,
  createOnpremToken,
  setUserFlags,
  updateUserState,
  saveSignupUser,
  saveProfile,
  getUser,
  getDfpDemoUser,
  getForensicsDemoUser,
  getSentinelDemoUser,
  logout,
  activateDeveloperInterface
} from 'global/redux/features/auth/authApiThunks'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'
import { config } from 'global/lib/config'

export interface CompleteFormValues {
  password: string
  confirmPassword: string
  phone: string
  isAcceptedPrivacyPolicy: boolean
}

export interface NewSignupUserForm extends User {
  password: string
  phoneNumber: string
  firstName: string
  lastName: string
  zipCode: string
  companyName: string
  address: string
  city: string
  country: string
}

export interface SignupUser {
  savedUser?: User
  user: NewSignupUserForm
  name?: string | undefined
  id?: string | undefined
}

export interface LogoutValues {
  accessTokenId: string
}

export interface AuthState {
  NEEDS_TO_CONNECT_O365_STATES: string[]
  NEEDS_TO_CONNECT_REMEDIATES_STATES: string[]
  savedCompleteFormValues: CompleteFormValues | undefined
  signup: SignupUser
  activatedDeveloperInterfaceKey: undefined | string
  signinApiStatus: ApiStatus
  signinOtpApiStatus: ApiStatus
  signupApiStatus: ApiStatus
  domainFraudApiStatus: ApiStatus
  createOnpremTokenApiStatus: ApiStatus
  newSignupApiStatus: ApiStatus
  getUserApiStatus: ApiStatus
  saveProfileApiStatus: ApiStatus
  saveSignupUserApiStatus: ApiStatus
  setUserFlagsApiStatus: ApiStatus
  updateUserStateApiStatus: ApiStatus
  logoutApiStatus: ApiStatus
  error: string | undefined
  logoutValues: LogoutValues | undefined
}

// initialState
export const INITIAL_STATE: AuthState = {
  NEEDS_TO_CONNECT_O365_STATES: [
    config.USER_STATES.CONNECT_OFFICE_365,
    config.USER_STATES.PROFILE,
    config.USER_STATES.CONNECT_OFFICE_365_EXISTING
  ],
  NEEDS_TO_CONNECT_REMEDIATES_STATES: [config.USER_STATES.CM_SETTINGS],
  savedCompleteFormValues: undefined,
  signup: {
    savedUser: {} as User,
    user: {} as NewSignupUserForm,
    name: undefined,
    id: undefined
  },
  activatedDeveloperInterfaceKey: undefined,
  signinApiStatus: inIdle,
  domainFraudApiStatus: inIdle,
  createOnpremTokenApiStatus: inIdle,
  signinOtpApiStatus: inIdle,
  signupApiStatus: inIdle,
  newSignupApiStatus: inIdle,
  getUserApiStatus: inIdle,
  saveProfileApiStatus: inIdle,
  saveSignupUserApiStatus: inIdle,
  setUserFlagsApiStatus: inIdle,
  updateUserStateApiStatus: inIdle,
  logoutApiStatus: inIdle,
  error: undefined,
  logoutValues: undefined
}

/* eslint-disable no-param-reassign */
export const authSlice = createSlice({
  name: 'AUTH',
  initialState: INITIAL_STATE,
  reducers: {
    setSignupUser: (state: AuthState, action: PayloadAction<SignupUser>) => {
      state.signup = action.payload
    },
    setError: (state: AuthState, action: PayloadAction<string>) => {
      state.error = action.payload
    },
    saveSignupCompleteForm: (state: AuthState, action: PayloadAction<CompleteFormValues | undefined>) => {
      state.savedCompleteFormValues = action.payload
    },
    resetSignupUser: (state: AuthState) => {
      state.signup = { ...INITIAL_STATE.signup }
    },
    resetError: (state: AuthState) => {
      state.error = INITIAL_STATE.error
    },
    resetSetUserFlag: (state: AuthState) => {
      state.setUserFlagsApiStatus = INITIAL_STATE.setUserFlagsApiStatus
    },
    reset: (state: AuthState) => {
      return {
        ...INITIAL_STATE,
        error: state.error
      }
    }
  },
  extraReducers: builder => {
    builder
      // signin
      .addCase(signin.pending, state => {
        state.signinApiStatus = inProgress
      })
      .addCase(signin.fulfilled, state => {
        state.signinApiStatus = successResponse
      })
      .addCase(signin.rejected, (state, action) => {
        state.signinApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // siginOtp
      .addCase(signinOtp.pending, state => {
        state.signinOtpApiStatus = inProgress
      })
      .addCase(signinOtp.fulfilled, state => {
        state.signinOtpApiStatus = successResponse
      })
      .addCase(signinOtp.rejected, (state, action) => {
        state.signinOtpApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // signup
      .addCase(signup.pending, state => {
        state.signinApiStatus = inIdle
        state.signupApiStatus = inProgress
      })
      .addCase(signup.fulfilled, state => {
        state.signupApiStatus = successResponse
      })
      .addCase(signup.rejected, (state, action) => {
        state.signupApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // signup
      .addCase(domainFraudSignup.pending, state => {
        state.domainFraudApiStatus = inProgress
      })
      .addCase(domainFraudSignup.fulfilled, state => {
        state.domainFraudApiStatus = successResponse
      })
      .addCase(domainFraudSignup.rejected, (state, action) => {
        state.domainFraudApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // Create on prem token
      .addCase(createOnpremToken.pending, state => {
        state.createOnpremTokenApiStatus = inProgress
      })
      .addCase(createOnpremToken.fulfilled, state => {
        state.createOnpremTokenApiStatus = successResponse
      })
      .addCase(createOnpremToken.rejected, (state, action) => {
        state.createOnpremTokenApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // newSignup
      .addCase(newSignup.pending, (state, action: AnyAction) => {
        state.signinApiStatus = inIdle
        state.newSignupApiStatus = inProgress
        state.signup.user = {
          ...state.signup.user,
          password: action.meta.arg.password,
          phoneNumber: action.meta.arg.phone
        }
      })
      .addCase(newSignup.fulfilled, (state, action) => {
        state.newSignupApiStatus = successResponse
        state.signup.savedUser = action.payload
      })
      .addCase(newSignup.rejected, (state, action) => {
        state.newSignupApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // setUserFlags
      .addCase(setUserFlags.pending, state => {
        state.setUserFlagsApiStatus = inProgress
      })
      .addCase(setUserFlags.fulfilled, state => {
        state.setUserFlagsApiStatus = successResponse
      })
      .addCase(setUserFlags.rejected, (state, action) => {
        state.setUserFlagsApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // updateUserState
      .addCase(updateUserState.pending, state => {
        state.updateUserStateApiStatus = inProgress
      })
      .addCase(updateUserState.fulfilled, state => {
        state.updateUserStateApiStatus = successResponse
      })
      .addCase(updateUserState.rejected, (state, action) => {
        state.updateUserStateApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // saveSignupUser
      .addCase(saveSignupUser.pending, state => {
        state.saveSignupUserApiStatus = inProgress
      })
      .addCase(saveSignupUser.fulfilled, state => {
        state.saveSignupUserApiStatus = successResponse
      })
      .addCase(saveSignupUser.rejected, (state, action) => {
        state.saveSignupUserApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // saveProfile
      .addCase(saveProfile.pending, state => {
        state.saveProfileApiStatus = inProgress
      })
      .addCase(saveProfile.fulfilled, (state, action) => {
        state.saveProfileApiStatus = successResponse
        state.signup.savedUser = action.payload
      })
      .addCase(saveProfile.rejected, (state, action) => {
        state.saveProfileApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // getUser
      .addCase(getUser.pending, state => {
        state.getUserApiStatus = inProgress
      })
      .addCase(getUser.fulfilled, state => {
        state.getUserApiStatus = successResponse
      })
      .addCase(getUser.rejected, (state, action) => {
        state.getUserApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // getDfpDemoUser
      .addCase(getDfpDemoUser.pending, state => {
        state.getUserApiStatus = inProgress
      })
      .addCase(getDfpDemoUser.fulfilled, state => {
        state.getUserApiStatus = successResponse
      })
      .addCase(getDfpDemoUser.rejected, (state, action) => {
        state.getUserApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // getForensicsDemoUser
      .addCase(getForensicsDemoUser.pending, state => {
        state.getUserApiStatus = inProgress
      })
      .addCase(getForensicsDemoUser.fulfilled, state => {
        state.getUserApiStatus = successResponse
      })
      .addCase(getForensicsDemoUser.rejected, (state, action) => {
        state.getUserApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // getSentinelDemoUser
      .addCase(getSentinelDemoUser.pending, state => {
        state.getUserApiStatus = inProgress
      })
      .addCase(getSentinelDemoUser.fulfilled, state => {
        state.getUserApiStatus = successResponse
      })
      .addCase(getSentinelDemoUser.rejected, (state, action) => {
        state.getUserApiStatus = failedResponse(action.payload as string)
        state.error = action.payload as string
      })

      // activateDeveloperInterface
      .addCase(activateDeveloperInterface.pending, state => {
        state.activatedDeveloperInterfaceKey = config.developerInterfaceKey
      })

      // logout
      .addCase(logout.pending, state => {
        state.logoutApiStatus = inProgress
      })
      .addCase(logout.fulfilled, state => {
        state.logoutApiStatus = successResponse
      })
      .addCase(logout.rejected, (state, action) => {
        state.error = action.payload as string
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  setSignupUser,
  setError,
  saveSignupCompleteForm,
  resetSignupUser,
  resetError,
  resetSetUserFlag,
  reset
} = authSlice.actions

export {
  setSuccessCallbacks,
  setFailedCallbacks,
  signin,
  signinOtp,
  signup,
  newSignup,
  domainFraudSignup,
  createOnpremToken,
  setUserFlags,
  updateUserState,
  saveSignupUser,
  saveProfile,
  getUser,
  getDfpDemoUser,
  getForensicsDemoUser,
  getSentinelDemoUser,
  logout,
  activateDeveloperInterface
}

export default authSlice.reducer
