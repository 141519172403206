// Todo: Remove entire file for BNCASB-5258
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => {
  return {
    footerWrapper: {
      marginTop: 10
    },
    footer1: {
      fontSize: 12,
      fontWeight: 400,
      color: 'white'
    },
    footer2: {
      fontSize: 12,
      fontWeight: 400,
      color: 'white',
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }
})
