import React, { useMemo } from 'react'

import { DialogContent, Grid } from '@barracuda-internal/bds-core'

import TabPanel from 'global/components/lib/tabPanel/TabPanel'
import Tabs from 'global/components/lib/Tabs/Tabs'

import SpearPhishingTab from 'sen/components/pages/settings/spearPhishingTab/SpearPhishingTab'
import AtoTab from 'sen/components/pages/settings/atoTab/AtoTab'
import AllowedSendersTab from 'sen/components/pages/settings/allowedSendersTab/AllowedSendersTab'
import SyslogIntegrationTab from 'sen/components/pages/settings/syslogIntegrationTab/SyslogIntegrationTab'
import useSettingsLogic from 'sen/components/pages/settings/useSettingsLogic'

export interface SettingsProps {
  onClose: () => void
}

export const Settings: React.FC<SettingsProps> = () => {
  const [settingsLogic] = useSettingsLogic()
  const { settingsTabsConfig, tabsConfig } = settingsLogic

  return useMemo(
    () => (
      <Grid container>
        <Grid item xs={3}>
          <Tabs {...tabsConfig} />
        </Grid>
        <Grid item xs={9}>
          <DialogContent>
            {[SpearPhishingTab, AtoTab, AllowedSendersTab, SyslogIntegrationTab].map((TabComponent: any, index) => (
              <TabPanel
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                index={index}
                value={settingsTabsConfig.activeTab as number}
              >
                <TabComponent />
              </TabPanel>
            ))}
          </DialogContent>
        </Grid>
      </Grid>
    ),
    [tabsConfig, settingsTabsConfig.activeTab]
  )
}

export default Settings
