import * as Sentry from '@sentry/react'
import { LicenseInfo } from '@mui/x-license-pro'
import geoip from 'i18n-iso-countries'
import geoipEn from 'i18n-iso-countries/langs/en.json'
import initConfig, { addAppConfig } from 'global/lib/config'

import { initRestClient } from 'global/lib/api/restClient'
import { init as initDeveloperInterface } from 'global/lib/developerInterface/developerInterface'
import * as intercomService from 'global/lib/analytics/intercomService'

// dfp
import dfpConfig from 'dfp/configs/app_config'
import dfpRequestMiddleware from 'dfp/lib/api/requestMiddleware'
import dfpResponseError from 'dfp/lib/api/responseError'

// ets
import etsConfig from 'ets/configs/app_config'
import etsRequestMiddleware from 'ets/lib/api/requestMiddleware'
import etsResponseError from 'ets/lib/api/responseError'

// fir
import firConfig from 'fir/configs/app_config'
import firRequestMiddleware from 'fir/lib/api/requestMiddleware'
import firResponseError from 'fir/lib/api/responseError'

// sen
import senConfig from 'sen/configs/app_config'
import senRequestMiddleware from 'sen/lib/api/requestMiddleware'
import senResponseError from 'sen/lib/api/responseError'

// admin
import adminConfig from 'admin/configs/app_config'
import adminRequestMiddleware from 'admin/lib/api/requestMiddleware'
import adminResponseError from 'admin/lib/api/responseError'

// i18n

import onetrustCookieAcceptance from './oneTrustCookieAcceptance'

// Register all of the supported language localization
geoip.registerLocale(geoipEn)

export interface HTMLHeaders {
  title: string
  description: string
}

// TODO remove /v2 replace after 30ish days
// replace /v2/ with / to handle old email links correctly
if (window.location.href.includes('/v2/')) {
  window.location.replace(window.location.href.replace('/v2/', '/'))
}

// Init
initConfig({}).then(config => {
  initDeveloperInterface()
  const isAdmin = window.location.pathname.includes('/admin')

  // Adds MUI X license key for every app
  LicenseInfo.setLicenseKey(config.muiXLicense)

  switch (true) {
    // disable admin
    case isAdmin:
      initRestClient({
        requestMiddleware: adminRequestMiddleware,
        responseError: adminResponseError
      })

      addAppConfig(adminConfig)

      Sentry.init({
        dsn: config.frontendSentryDsn,
        environment: config.appEnv
      })
      /* eslint-disable-next-line global-require */
      require('./admin/App').AdminApp()
      break

    // Incident Response APP
    case config.domainConfig.isForensics:
      initRestClient({
        requestMiddleware: firRequestMiddleware,
        responseError: firResponseError
      })

      addAppConfig(firConfig)
      onetrustCookieAcceptance(config.onetrustIds.forensics)

      Sentry.init({
        dsn: config.frontendSentryDsn,
        environment: config.appEnv
      })
      /* eslint-disable-next-line global-require */
      require('./fir/App').FirApp()
      break

    // Sentinel APP
    case config.domainConfig.isSentinel:
      initRestClient({
        requestMiddleware: senRequestMiddleware,
        responseError: senResponseError
      })

      addAppConfig(senConfig)
      onetrustCookieAcceptance(config.onetrustIds.sentinel)
      /* eslint-disable-next-line global-require */
      require('./sen/App').SENApp()
      break

    // Domain Fraud Protection APP
    case !config.isProd && config.domainConfig.isDfp:
      initRestClient({
        requestMiddleware: dfpRequestMiddleware,
        responseError: dfpResponseError
      })

      addAppConfig(dfpConfig)
      onetrustCookieAcceptance(config.onetrustIds.sentinel)
      /* eslint-disable-next-line global-require */
      require('./dfp/App').DfpApp()
      break

    // ETS App
    default:
      initRestClient({
        requestMiddleware: etsRequestMiddleware,
        responseError: etsResponseError
      })

      addAppConfig(etsConfig)
      onetrustCookieAcceptance(config.onetrustIds?.ets)

      /* eslint-disable-next-line global-require */
      require('./ets/App').ETSApp()
      break
  }
  intercomService.initialize()
})
