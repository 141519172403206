// Todo: Remove entire file for BNCASB-5258
import React, { useMemo, useCallback } from 'react'

import { Button, Typography, TextField, Select, MenuItem, Grid } from '@barracuda-internal/bds-core'
import { EpDomainFraudProtection } from '@barracuda-internal/bds-core/dist/Logos/Products'

import globalStyles from 'global/components/features/signinSignup/signinSignupStyles'
import { useFormatMessage } from 'global/lib/localization'
import { getCountryList } from 'global/lib/isoCountries'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { onKeyDown } from 'global/lib/keyEvents'

import SigninSignupLayout from 'sen/components/lib/layout/SigninSignupLayout'
import usedomainFraudSignupProfileLogic from 'sen/components/pages/signup/domainFraudSignupProfile/useDomainFraudSignupProfileLogic'
import domainFraudstyles, { wrapperStyles } from 'sen/components/pages/signup/domainFraudSignup/domainFraudSignupStyles'
import profileStyles from 'sen/components/pages/signup/domainFraudSignupProfile/domainFraudSignupProfileStyles'

const BASE_I18N_KEY = 'sen.app.domain_fraud_signup'

const DomainFraudSignupProfile: React.FC = () => {
  const profileClasses = profileStyles()
  const classes = domainFraudstyles()
  const globalClasses = globalStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [inProgress, form, formHandlers, formStates, errors] = usedomainFraudSignupProfileLogic()

  const renderTextField = useCallback(
    (field: string, restProps = {}) => {
      return (
        <TextField
          type={field === 'password' ? 'password' : 'text'}
          className={classes.input}
          placeholder={formatMessage(`placeholders.${field}`)}
          value={form.values[field]}
          onKeyDown={onKeyDown(['Enter'], !formStates.isSignupButtonDisabled && formHandlers.onContinue)}
          onChange={(e: any) => {
            form.setFormValues({ [field]: e.target.value })
          }}
          disabled={inProgress}
          data-field={field}
          {...restProps}
        />
      )
    },
    [form, classes, formatMessage, formStates, formHandlers, inProgress]
  )

  return useMemo(() => {
    return (
      <SigninSignupLayout error={errors.signup}>
        <SigninSignupLayout.Wrapper isFormInProgress={false} style={wrapperStyles}>
          {inProgress && <LinearProgress className={classes.linearProgress} data-field="linear-progress" />}
          <Grid className={classes.header} item xs={12}>
            <Grid container item xs={12} className={globalClasses.headingLogoWrapper}>
              <EpDomainFraudProtection alt="" />
            </Grid>
          </Grid>
          <SigninSignupLayout.Content>
            <Typography className={classes.formTitle} data-field="form-title">
              {formatMessage('complete_form_title')}
            </Typography>

            {renderTextField('email', { disabled: true })}
            {renderTextField('name')}
            {renderTextField('company')}
            {renderTextField('phone', { error: errors.form.isInvalidPhone })}
            {renderTextField('zip')}

            <Select
              className={classes.input}
              displayEmpty
              value={form.values.country}
              disabled={inProgress}
              renderValue={(selected: any) => {
                if (!selected?.length) {
                  return <data className={classes.selectPlaceholder}>{formatMessage('placeholders.country')}</data>
                }

                return selected
              }}
              onKeyDown={onKeyDown(['Enter'], !formStates.isSignupButtonDisabled && formHandlers.onContinue)}
              onChange={(e: any) => {
                form.setFormValues({ country: e.target.value })
              }}
              data-field="select-country"
            >
              <MenuItem disabled value="">
                <data className={classes.selectPlaceholder}>{formatMessage('placeholders.country')}</data>
              </MenuItem>
              {getCountryList().map((menuItem: any) => (
                <MenuItem className={classes.countryMenuItem} key={menuItem.code} value={menuItem.code}>
                  {menuItem.name}
                </MenuItem>
              ))}
            </Select>

            <Grid item xs={12}>
              <Button
                className={classes.signupButton}
                onClick={formHandlers.onContinue}
                disabled={formStates.isSignupButtonDisabled}
                color="primary"
                variant="contained"
                data-action="continue"
              >
                {formatMessage('profile_button')}
              </Button>
            </Grid>
          </SigninSignupLayout.Content>
        </SigninSignupLayout.Wrapper>
        <div className={profileClasses.footerWrapper}>
          <Typography className={profileClasses.footer1} align="center" data-field="footer-1">
            {form.values.email}
          </Typography>
          {!inProgress && (
            <Typography
              className={profileClasses.footer2}
              align="center"
              role="presentation"
              onClick={formHandlers.onUseAnotherUser}
              data-field="footer-2"
            >
              {formatMessage('footer_text')}
            </Typography>
          )}
        </div>
      </SigninSignupLayout>
    )
  }, [
    globalClasses,
    profileClasses,
    formatMessage,
    inProgress,
    errors,
    form,
    formHandlers,
    classes,
    formStates,
    renderTextField
  ])
}

export default DomainFraudSignupProfile
