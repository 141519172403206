import { config } from 'global/lib/config'

export function generateUriParams(params: any) {
  return Object.keys(params)
    .filter((param: any) => params[param] !== null)
    .reduce((all: string, param: any) => {
      return `${all}${all.length ? '&' : '?'}${param}=${params[param]}`
    }, '')
}

/**
 * Configuration, artist formally known as ConfigurationService on CASB
 *
 * Functions not included:
 * initializePeriodicConfigurationUpdate
 * getUpgradeMaxMailboxCount
 * getSentinelDomain
 * exportgetSentinelReportUrl
 */
export function getConnectOffice365Uri(
  provider: string,
  scanType: string,
  source: string,
  accountId: string | null = null,
  isReadOnly = false
): string {
  const uriParams = generateUriParams({ accountId, isReadOnly })

  return `/${config.API_VERSION}/api${config.office365Uri[provider][scanType][source]}${uriParams}`
}

export function getReconnectOffice365Uri(
  provider: string,
  scanType: string,
  source: string,
  accountId: string,
  accessTokenId: string
): string {
  return `/${config.API_VERSION}/api${config.office365Uri[provider][scanType][source]}?accountId=${accountId}&accessTokenId=${accessTokenId}`
}

export function getPasswordResetOffice365Uri(
  provider: string,
  source: string,
  scanType: string,
  accountId: string,
  accessTokenId: string,
  userPrincipalName: string
): string {
  return `/${config.API_VERSION}/api${config.office365Uri[provider][scanType][source]}?accountId=${accountId}&accessTokenId=${accessTokenId}&userPrincipalName=${userPrincipalName}`
}

export function getBccSigninUri(scanType: string) {
  return `/${config.API_VERSION}/api${config.bccSigninUri[scanType]}`
}

export function getEtsReportUrl(accessTokenId: string): string {
  return `${config.domains.ets}/report/${accessTokenId}`
}

export function getForensicsReportUrl(accessTokenId: string): string {
  return `${config.domains.forensics}/report/${accessTokenId}`
}

export function getFirNoticeableInfo() {
  return config.forensicsNoticeable
}

export function getSentinelNoticeableInfo() {
  return config.sentinelNoticeable
}

export function getAnalystsEmail() {
  return config.emails.analysts
}

export function getBCCDomain() {
  return config.domains.bcc
}

export function getTrainingDomain() {
  return config.domains.training
}

export function getProductId() {
  switch (true) {
    case config.domainConfig.isDfp:
      return config.PRODUCTS.DFP
    case config.domainConfig.isEts:
      return config.PRODUCTS.ETS
    case config.domainConfig.isForensics:
      return config.PRODUCTS.FORENSICS
    case config.domainConfig.isSentinel:
      return config.PRODUCTS.SENTINEL
    default:
      return 'Unknown/Undefined'
  }
}

export function getProductAbbr() {
  switch (true) {
    case config.domainConfig.isDfp:
      return config.PRODUCT_ABBR.DFP
    case config.domainConfig.isEts:
      return config.PRODUCT_ABBR.ETS
    case config.domainConfig.isForensics:
      return config.PRODUCT_ABBR.FORENSICS
    case config.domainConfig.isSentinel:
      return config.PRODUCT_ABBR.SENTINEL
    default:
      return 'Unknown/Undefined'
  }
}

export function getSpAutoRefreshFreq() {
  return config.autoRefresh.spRefreshFreqMillis
}
