import React from 'react'

import { EpDomainFraudProtection } from '@barracuda-internal/bds-core/dist/Logos/Products'

import GlobalSignupComplete from 'global/components/features/signinSignup/signupComplete/SignupComplete'
import { config } from 'global/lib/config'
import { User } from 'global/types/api/userType'

import routesConfig from 'dfp/lib/routes/routesConfig'

interface SigninSignupCompleteProps {
  user: User
  reportId: string
}

export const SigninSignupComplete: React.FC<SigninSignupCompleteProps> = ({ user, reportId }) => (
  <GlobalSignupComplete
    isFinalStep={false}
    productLogo={<EpDomainFraudProtection alt="Domain Fraud Protection" />}
    routesConfig={routesConfig}
    gotoNextStep={() => {
      routesConfig.DOMAIN_FRAUD.goto({ user, reportId })
    }}
    scanType={config.SCAN_TYPES.DFP}
  />
)

export default SigninSignupComplete
