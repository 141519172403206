import setAuthCallbacks from 'global/redux/features/auth/authCallbacks'

import routesConfig from 'dfp/lib/routes/routesConfig'
import { setUser } from 'dfp/redux/features/user/userSlice'
import gotoReportPage from 'dfp/lib/gotoReportPage'

setAuthCallbacks({
  routesConfig,
  setUser,
  gotoReportPage
})
