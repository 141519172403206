import { useMemo, useEffect, useReducer } from 'react'

import { useAppDispatch } from 'global/redux/toolkit/hooks'

import { TabsProps } from 'global/components/lib/Tabs/Tabs'
import { useFormatMessage } from 'global/lib/localization'

import { reset as resetSettings, resetError as resetSettingsError } from 'sen/redux/features/settings/settingsSlice'

export interface SettingsProps {
  pageTitle: string
  tabsConfig: TabsProps
  settingsTabsConfig: any
}

const BASE_I18N_KEY = 'sen.app.settings_dialog'
const TABS = ['spear_phishing', 'ato', 'allowed_senders', 'syslog_integration']

export default function useSettingsLogic(defaultTab = 0): [SettingsProps] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    activeTab: TABS[0]
  })

  // init
  useEffect(() => {
    // unmount
    return () => {
      dispatch(resetSettings())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tabsConfig = useMemo(() => {
    return {
      activeTab: state.activeTab,
      tabs: TABS.map((tab: any) => ({
        id: tab,
        onClick: () => {
          dispatch(resetSettingsError())
          setState({ activeTab: tab })
        },
        label: formatMessage(`tabs.${tab}`)
      })),
      vertical: true
    }
  }, [state.activeTab, formatMessage, dispatch])

  return useMemo(() => {
    return [
      {
        pageTitle: formatMessage('page_title'),
        settingsTabsConfig: { activeTab: TABS.indexOf(state.activeTab) },
        tabsConfig
      }
    ]
  }, [formatMessage, state.activeTab, tabsConfig])
}
