import { makeStyles } from '@material-ui/core/styles'

import tabStyles from 'global/theme/tabStyles'

export default makeStyles(theme => {
  const tabClasses = tabStyles(theme)

  return {
    ...tabClasses,
    tabContent: {
      ...tabClasses.tabContent,
      padding: 0,
      position: 'relative'
    },
    error: {
      marginBottom: theme.spacing(2)
    },
    loadingIndicator: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0
    },
    buttonsContainer: {
      marginBottom: theme.spacing(2)
    },
    formButton: {
      padding: `0 ${theme.spacing(2)}px`,
      marginLeft: theme.spacing(1)
    }
  }
})
