import React, { useMemo } from 'react'

import { Typography, Button, TextField, Switch, Grid, Alert } from '@barracuda-internal/bds-core'
import { Report as ReportIcon } from '@barracuda-internal/bds-core/dist/Icons/Email'
import { CheckmarkCircle as CheckCircleIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'

import useSyslogIntegrationTabLogic from 'sen/components/pages/settings/syslogIntegrationTab/useSyslogIntegrationTabLogic'
import settingsStyles from 'sen/components/pages/settings/settingsStyles'
import styles from 'sen/components/pages/settings/syslogIntegrationTab/syslogIntegrationTabStyles'

const BASE_I18N_KEY = 'sen.app.settings_dialog.syslog_integration_tab'

function validateToNumbers(string: string) {
  return string.replace(/\D/g, '')
}

export const SyslogIntegrationTab: React.FC = () => {
  const classes = styles()
  const settingsClasses = settingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()

  const [isTabInProgress, formValues, formHandlers, formStates] = useSyslogIntegrationTabLogic()

  return useMemo(
    () => (
      <Grid container className={settingsClasses.tabContent} direction="column">
        {formStates.error && (
          <Alert className={settingsClasses.error} severity="error">
            {errorFormatMessage(formStates.error)}
          </Alert>
        )}

        {isTabInProgress && <LinearProgress className={settingsClasses.loadingIndicator} />}

        <Grid container className={classes.formRow} alignItems="center" direction="row">
          <Grid item xs={4}>
            <Typography>{formatMessage('active')}</Typography>
          </Grid>
          <Grid item>
            <Switch
              color="primary"
              className={classes.switcher}
              checked={formValues.isActive}
              disabled={isTabInProgress}
              onChange={(e: any) => {
                formHandlers.onFormChange({ isActive: e.target.checked })
              }}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.formRow} alignItems="center" direction="row">
          <Grid item xs={4}>
            <TextField
              label={formatMessage('ip_address_hostname')}
              className={classes.textField}
              onChange={(e: any) => {
                formHandlers.onFormChange({ syslogHost: e.target?.value?.trim() })
              }}
              value={formValues.syslogHost}
              disabled={isTabInProgress || !formValues.isActive}
              error={formValues.isActive && !formStates.isValidHost}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.formRow} alignItems="center" direction="row">
          <Grid item>
            <TextField
              label={formatMessage('port')}
              className={classes.textField}
              onChange={(e: any) => {
                formHandlers.onFormChange({ syslogPort: Number(validateToNumbers(e.target.value)) })
              }}
              value={String(formValues.syslogPort)}
              disabled={isTabInProgress || !formValues.isActive}
              error={formValues.isActive && !formStates.isValidPort}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.actionButtons} alignItems="center" direction="row">
          <Grid item>
            <Button
              className={classes.actionButton}
              onClick={formHandlers.onTest}
              disabled={formStates.isTestButtonDisabled}
              color="primary"
              variant="outlined"
              size="large"
            >
              {formatMessage('test')}
            </Button>

            <Button
              className={classes.actionButton}
              onClick={formHandlers.onSave}
              disabled={formStates.isSaveButtonDisabled}
              color="primary"
              variant="contained"
              size="large"
            >
              {formatMessage('save')}
            </Button>
          </Grid>
        </Grid>

        <Grid container className={classes.testResultsRow} alignItems="center" direction="row">
          <Grid item>
            <Typography>
              {formStates.isSuccessTest && (
                <>
                  <CheckCircleIcon className={classes.successIcon} />
                  {formatMessage('test_success')}
                </>
              )}
              {formStates.isFailedTest && (
                <>
                  <ReportIcon className={classes.failedIcon} />
                  {formatMessage('test_failed')}
                </>
              )}
              &nbsp;
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.formRow} alignItems="center" direction="row">
          <Typography>{formatMessage('note')}</Typography>
        </Grid>
      </Grid>
    ),
    [classes, formatMessage, errorFormatMessage, settingsClasses, formValues, formHandlers, formStates, isTabInProgress]
  )
}

export default SyslogIntegrationTab
