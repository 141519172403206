/* eslint-disable quotes */
const API_VERSION = 'v2'

const APP = {
  API_VERSION,
  VALIDATORS: {
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S]{8,}$/,
    PHONE: /^[+]{0,1}[0-9]{0,3}\s{0,1}[(]{0,1}[0-9]{1,3}[)]{0,1}\s{0,1}[-\s]?[\d]{3}[\s-]?[\d]{4}$/,
    ACCESS_TOKEN: /[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}/,
    VALID_MSTEAMS_WEBHOOK_CONTAINS: '.logic.azure.com:443/workflows/',
    VALID_SLACK_WEBHOOK_STARTS: 'https://hooks.slack.com/services/'
  },
  LINKS: {
    SUPPORT: 'https://www.barracuda.com/support',
    PRIVACY_POLICY: 'https://www.barracuda.com/support/security',
    CAMPUS_AUTOMATIC_REMEDIATION: 'https://campus.barracuda.com/product/forensics/doc/91987361/automatic-remediation',
    CAMPUS_CREATE_INCIDENT: 'https://campus.barracuda.com/doc/79463562/',
    CAMPUS_GEOGRAPHICAL_INSIGHTS: 'https://campus.barracuda.com/doc/84312849/',
    CAMPUS_POTENTIAL_INCIDENTS: 'https://campus.barracuda.com/doc/90445902/',
    CAMPUS_SEARCHING_FOR_EMAILS: 'https://campus.barracuda.com/doc/91128973/',
    CAMPUS_SYSLOG: 'https://campus.barracuda.com/doc/93195062/',
    CAMPUS_USER_REPORTED: 'https://campus.barracuda.com/doc/84313664/',
    BARRACUDA_CONTENT_SHIELD: 'https://www.barracuda.com/products/email-protection/web-security',
    BARRACUDA_ESS_LOG: {
      NON_US: 'https://ess.REGION.barracudanetworks.com/log',
      US: 'https://ess.barracudanetworks.com/log'
    },
    BARRACUDA_ESSENTIALS: 'https://www.barracuda.com/products/essentials',
    BARRACUDA_OUTLOOK_ADDIN: 'https://appsource.microsoft.com/en-us/product/office/WA200005527',
    BARRACUDA_PHISHLINE_VIDEO: 'https://campus.barracuda.com/to/12US',
    DOMAIN_REGISTAR_SECTION: 'https://whois.icann.org/en/lookup?name=',
    MICROSOFT_ARTICLE_SPF_RECORD: 'https://technet.microsoft.com/en-us/library/dn789058(v=exchg.150).aspx',
    MICROSOFT_OFFICE365: 'https://www.office.com/',
    ECHO_NORTH_AMERICA: 'https://manage.barracudamsp.com/Login.aspx?ReturnUrl=%2fServices%2fEssentialsServices.aspx',
    ECHO_OUTSIDE_NORTH_AMERICA:
      'https://manage.echo.barracudamsp.com/Login.aspx?ReturnUrl=%2fServices%2fEssentialsServices.aspx',
    SENTINEL_REPORT_MISSED_ATTACK: 'https://campus.barracuda.com/doc/95263088/',
    OFFICE_SUPPORT_DISABLE_SIGN_IN:
      'https://support.office.com/en-us/article/remove-a-former-employee-from-office-365-44d96212-4d90-4027-9aa9-a95eddb367d1#bkmk_block',
    OFFICE_SUPPORT_RESET_PASSWORD:
      'https://support.office.com/en-us/article/Admins-Reset-Office-365-business-passwords-7a5d073b-7fae-4aa5-8f96-9ecd041aba9c?ui=en-US&rs=en-US&ad=US',
    OFFICE_SUPPORT_KILL_SESSIONS:
      'https://support.office.com/en-us/article/remove-a-former-employee-from-office-365-44d96212-4d90-4027-9aa9-a95eddb367d1#bkmk_now',
    OFFICE_SUPPORT_REMOVE_FORWARDING:
      'https://support.office.com/en-us/article/configure-email-forwarding-in-office-365-ab5eb117-0f22-4fa7-a662-3a6bdb0add74',
    OFFICE_ADMIN_PORTAL: 'https://admin.microsoft.com/AdminPortal/Home#/homepage',
    SPF_SETUP_DOC: 'https://campus.barracuda.com/doc/112165057/',
    DKIM_SETUP_DOC: 'https://campus.barracuda.com/doc/112165056/',
    DMARC_ALIGNMENT_SETUP_DOC: 'https://campus.barracuda.com/doc/112165055/'
  },
  SENDING_DIRECTION: {
    INCOMING: 'I',
    OUTGOING: 'O',
    UNKNOWN: 'U'
  },
  DATETIME: {
    DEFAULT_DATE_FORMAT: 'MMM dd, yyyy',
    DEFAULT_TIME_FORMAT: 'h:mm a',
    // eslint-disable quotes
    DEFAULT_DATE_WITH_TIME_FORMAT: "MMM dd, yyyy 'at' h:mm a",
    DEFAULT_DATE_WITH_TIME_FORMAT_WITH_SEC: "MMM dd, yyyy 'at' h:mm:s a",
    // eslint-enable quotes
    DEFAULT_HUMANIZE_DURATION_CONFIG: {
      units: ['d', 'h', 'm'],
      round: true
    },
    DATE_WITH_TIME_FORMAT_WITHOUT_AT: 'MMM dd, yyyy h:mm a',
    ADMIN_DATE_FORMAT: 'MM/dd/yy',
    MONTH_ONLY_FORMAT: 'MMMM',
    MONTH_WITH_DAY_FORMAT: 'MMMM dd',
    TIME_FORMAT: 'h a'
  },
  DATA_TABLE: {
    FORENSICS: {
      ITEMS_PER_PAGE: 10
    }
  },
  NUMBER: {
    DEFAULT_FORMAT: '0.[0]a',
    TWO_DIGITS_FORMAT: '0.00a'
  },
  ASSETS: {
    BASE_FILES_PATH: `/${API_VERSION}/assets/files/`,
    // BNCASB-5128: this file name may need to be updated, verify with Sheila Hara
    DFP: {
      DATA_PRIVACY: 'barracuda_email_threat_scanner_sb_data_privacy_us.pdf'
    },
    ETS: {
      DATA_PRIVACY: 'barracuda_email_threat_scanner_sb_data_privacy_us.pdf'
    },
    FORENSICS: {
      DATA_PRIVACY: 'barracuda_sentinel_sb_data_privacy_us.pdf'
    }
  },
  CLOUD_PROVIDERS: {
    office365: {
      id: 'office365',
      name: 'Office 365',
      fullTitle: 'Microsoft Office 365',
      enabled: true,
      accessToken: null
    },
    onprem: {
      id: 'onPrem',
      name: 'On Prem'
    }
  },
  SCAN_TYPES: {
    DFP: 'dfp',
    ETS: 'ets',
    FORENSICS: 'forensics',
    FORENSICS_BOOTSTRAP: 'forensics_bootstrap',
    SENTINEL: 'sentinel'
  },
  SCAN_SOURCES: {
    NAVBAR: 'navbar',
    SIGNUP: 'signup',
    COMPLETE_SIGNUP: 'completeSignup'
  },
  RECONNECT_SOURCES: {
    FORENSICS_RECONNECT: 'forensicsReconnect',
    ATO_WIZARD: 'atoWizard',
    ATO_TAB: 'atoTab',
    ATO_RULES_PAGE: 'atoRules',
    ATO_PASS_RESET: 'atoPasswordReset'
  },
  PRODUCT_ABBR: {
    DFP: 'DFP',
    ETS: 'ETS',
    FORENSICS: 'FIR',
    SENTINEL: 'SEN'
  },
  BUNDLES: {
    BUNDLE1: 'B1',
    BUNDLE2: 'B2',
    DP: 'DP'
  },
  FEATURE_SWITCHES: {
    ATO_ALERTS: 'ATO_ALERTS',
    DMARC_REPORT: 'DMARC_REPORT',
    FORENSICS_ACCESS_EXEMPTION: 'FORENSICS_ACCESS_EXEMPTION',
    FORENSICS_DASHBOARD: 'FORENSICS_DASHBOARD',
    FORENSICS_DEMO: 'FORENSICS_DEMO',
    PAID_ETS: 'PAID_ETS',
    PHISHING_SIMULATION: 'PHISHING_SIMULATION',
    REMEDIATION: 'REMEDIATION',
    REMEDIATION_DEMO: 'REMEDIATION_DEMO',
    SENTINEL_DASHBOARD: 'SENTINEL_DASHBOARD',
    SENTINEL_DEMO: 'SENTINEL_DEMO',
    SENTINEL_SERIAL_NUMBER_WARNING: 'SENTINEL_SERIAL_NUMBER_WARNING',
    SHARE_REPORT: 'SHARE_REPORT',
    SIMULATION_CAMPAIGNS: 'SIMULATION_CAMPAIGNS'
  },
  NO_TOKEN_TOKEN: 'no-token',
  ON_PREM_DEFAULTS: {
    NAME: 'Non-Office 365 Account'
  },
  OFFICE365_DEFAULTS: {
    UNKNOWN: 'Unknown Office 365 Account'
  },
  OFFICE365_PROVIDER_APPS: {
    FULL_APP: 'Sentinel',
    READ_ONLY_APP: 'ETS-Readonly'
  },
  PRODUCTS: {
    ETS: 'ets',
    FORENSICS: 'fir',
    SENTINEL: 'sentinel',
    DFP: 'dfp',
    IDS: {
      DFP: 200,
      FORENSICS: 25,
      SENTINEL: 20
    },
    STATES: {
      ACTIVE: 'A',
      CANCELED: 'C'
    },
    SUBSTATES: {
      LEGACY: 'G',
      TRIAL: 'T'
    }
  },
  USER_STATES: {
    CONNECT_OFFICE_365: 'connect-office365',
    CONNECT_OFFICE_365_EXISTING: 'connect-office365-existing',
    PROFILE: 'user-profile',
    PROFILE_O365_CONNECTED: 'user-profile-office365-connected',
    PROFILE_COMPLETED: 'profile-completed',
    CM_SETTINGS: 'cm-settings'
  },
  CM_SETTINGS: {
    ENFORCE_MODE: {
      name: 'enforce',
      value: {
        spAttackQuarantine: 1,
        spAttackAlertEndUser: 1,
        spAttackAlertAdmin: 1,
        spAttackAlertImpersonatedUser: 1,
        atoAttackAlertAdmin: 1
      }
    },
    REPORTING_MODE: {
      name: 'reporting',
      value: {
        spAttackQuarantine: 0,
        spAttackAlertEndUser: 0,
        spAttackAlertAdmin: 0,
        spAttackAlertImpersonatedUser: 0,
        atoAttackAlertAdmin: 0
      }
    }
  },
  // TODO: remove when we want to start showing trial expiration for all trials
  // trials that expire after this date will be shown the expiration banner
  TRACK_TRIAL_EXPIRATION_START_DATE: '2020-04-15T00:00:00.00000Z',
  EXPIRATION_WARNING_DAYS: 7,
  API_ERROR: {
    DEMO_ERROR: {
      FORENSICS: 'ForensicsDemoEnabledError',
      SENTINEL: 'SentinelDemoEnabledError'
    },
    INVALID_CREDENTIALS: 'InvalidCredentials',
    USER_ALREADY_EXISTS: 'UserAlreadyExists'
  },
  DOMAIN_RESOLVE_STATES: {
    DMARC: {
      LOADING: 'loading',
      PROTECTED: 'protected',
      UNPROTECTED: 'unprotected',
      REPORTING: 'reporting',
      MISCONFIGURED: 'misconfigured',
      ERROR: 'error',
      UNVERIFIED: 'unverified'
    },
    MX: {
      LOADING: 'loading',
      PROTECTED: 'protected',
      PARTIALLY_PROTECTED: 'partially-protected',
      UNPROTECTED: 'unprotected',
      ERROR: 'error',
      UNVERIFIED: 'unverified'
    }
  },
  DOMAIN_SOURCES: {
    O365: 'o365',
    INTERNAL: 'internal'
  },
  DMARC_POLICIES: {
    REJECT: 'reject',
    QUARANTINE: 'quarantine',
    NONE: 'none'
  },
  DMARC_RUF_DATA_TYPES: {
    FRAUD: 'fraud',
    MAJOR: 'major',
    MINOR: 'minor'
  },
  SP_SETTINGS: {
    EMAIL_TEMPLATE_NAMES: {
      SP_QUARANTINED_USER: 'sp_quarantined_user'
    },
    EMAIL_FROM: '<noreply@barracuda.com>'
  },
  CSV_EXPORT_COOKIE: {
    MAX_RETRIES: 300,
    CHECK_INTERVAL: 200
  },
  ALL_SIGNINS_COLLECTION_START_DATE: '2019-04-12',
  LICENSING_COMPLIANCE_PATH: `/${API_VERSION}/api/licenses`
}

export default APP
