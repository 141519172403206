import { combineReducers, AnyAction } from 'redux'
import { splitReducer } from '@splitsoftware/splitio-redux'

// global slices
import dataTables, { INITIAL_STATE as dataTablesInitialState } from 'dfp/redux/features/dataTables/dataTablesSlice'
import accessToken, {
  INITIAL_STATE as accessTokenInitialState
} from 'global/redux/features/accessToken/accessTokenSlice'
import account from 'global/redux/features/account/accountSlice'
import admin, { INITIAL_STATE as adminInitialState } from 'global/redux/features/admin/adminSlice'
import analytics, { INITIAL_STATE as analyticsInitialState } from 'global/redux/features/analytics/analyticsSlice'
import app, { INITIAL_STATE as appInitialState } from 'global/redux/features/app/appSlice'
import auth, { logout } from 'global/redux/features/auth/authSlice'

import dmarc, { INITIAL_STATE as dmarcInitialState } from 'dfp/redux/features/dmarc/dmarcSlice'

// dfp slices
import 'dfp/redux/features/auth/authSlice'
import user, { INITIAL_STATE as userInitialState } from 'dfp/redux/features/user/userSlice'

import { RootState } from './toolkit/store'

export const reducers = {
  accessToken,
  account,
  admin,
  analytics,
  app,
  auth,
  dataTables,
  dmarc,
  user,
  splitio: splitReducer
}

const combinedReducers = combineReducers(reducers)

export default function(state: RootState, action: AnyAction) {
  let appState = { ...state }

  if (logout.pending.match(action) && appState.accessToken?.accessToken?.id) {
    appState.auth = {
      ...appState.auth,
      logoutValues: {
        accessTokenId: appState.accessToken?.accessToken?.id
      }
    }
  }

  if (logout.fulfilled.match(action)) {
    // reset stores on logout
    appState = {
      ...appState,
      splitio: {
        ...appState.splitio,
        treatments: {}
      },
      app: {
        ...appInitialState,
        metadata: appState.app.metadata
      },
      auth: {
        ...appState.auth
      },
      accessToken: accessTokenInitialState,
      analytics: analyticsInitialState,
      admin: adminInitialState,
      dataTables: dataTablesInitialState,
      dmarc: dmarcInitialState,
      user: userInitialState
    }
  }

  return combinedReducers(appState, action)
}
