// Todo: Remove entire file for BNCASB-5258
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { COLORS } from 'global/configs/theme/defaults'

export const WRAPPER_WIDTH = 585

export const wrapperStyles = {
  width: WRAPPER_WIDTH,
  padding: '0 88px',
  position: 'relative'
}

export default makeStyles(theme => {
  const commonStyles = createStyles({
    title: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left'
    }
  })

  return {
    title: commonStyles.title,
    formTitle: {
      ...commonStyles.title,
      marginBottom: theme.spacing(1.5)
    },
    header: {
      marginBottom: theme.spacing(4)
    },
    input: {
      width: '100%',
      margin: 0,
      marginBottom: theme.spacing(2)
    },
    signupButton: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    countryMenuItem: {
      width: '100%'
    },
    selectPlaceholder: {
      opacity: 0.3,
      color: '#191919'
    },
    linkButton: {
      margin: `${theme.spacing(2)}px 0`,
      width: '100%'
    },
    privacyPolicy: {
      textAlign: 'center',
      fonStyle: 'normal',
      fontWeight: 400,
      fontSize: 12
    },
    privacyLink: {
      cursor: 'pointer',
      textDecoration: 'underline'
    },
    linearProgress: {
      position: 'absolute',
      left: 0,
      height: 4,
      right: 0,
      top: 0
    },
    formError: {
      color: COLORS.ERROR,
      fontSize: 12,
      fontWeight: 400,
      marginTop: -15,
      marginBottom: theme.spacing(1),

      '& li': {
        listStyleType: 'none'
      }
    }
  }
})
