import React, { useCallback, useMemo } from 'react'

import { NavigationBarProps, ProfileAvatar, ProfileMenu } from '@barracuda-internal/bds-core'
import { Apps, SupervisorAccount, OpenInNew, ExitToApp } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { FaviconEmail } from '@barracuda-internal/bds-core/dist/Logos/Favicons'

import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'
import { logout } from 'global/redux/features/auth/authSlice'
import useUserDataLib from 'global/lib/userData/useUserData'

import { useAppDispatch, useAppSelector } from 'dfp/redux/toolkit/hooks'

const BASE_I18N_KEY = 'dfp.app.navbar'

export default function useDfpNavbarLogic(): [NavigationBarProps] {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [userDataLib] = useUserDataLib()
  const dispatch = useAppDispatch()
  const { accessTokenId, userBccId, userEmail, userName } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    userBccId: _stores.user.data.bccUserId,
    userEmail: _stores.user.data.email,
    userName: _stores.user.data.displayName
  }))

  const isAdmin = useMemo(() => userDataLib.isUserAdmin(accessTokenId), [accessTokenId, userDataLib])

  const userInitials = useMemo(() => {
    if (!userName) return ''
    const initials = userName
      .split(' ')
      .reduce(
        (acc, name, i, arr) => {
          if (i === 0) return [name.charAt(0).toUpperCase()]
          if (i === arr.length - 1) return [...acc, name[0].toUpperCase()]
          return acc
        },
        ['']
      )
      .join('')
    return initials
  }, [userName])

  // start logout
  const onLogout = useCallback(() => {
    dispatch(logout(true))
  }, [dispatch])

  const options = useMemo(
    () => [
      {
        name: formatMessage('options.apps'),
        icon: <Apps />,
        menu: 'Custom menu content.' // This is a placeholder for the actual app menu content
      },
      {
        name: formatMessage('options.profile'),
        icon: <ProfileAvatar size={28}>{userInitials}</ProfileAvatar>,
        menu: (
          <ProfileMenu
            icon={<ProfileAvatar>{userInitials}</ProfileAvatar>}
            name={userName}
            email={userEmail}
            menuItems={[
              ...(isAdmin
                ? [
                    {
                      name: formatMessage('options.profileMenu.manageAccount'),
                      onClick: (): void => {
                        window.open(`${config.bccAccountUserUrl}/#user=${userBccId}`, '_blank')
                      },
                      icon: <SupervisorAccount />,
                      endIcon: <OpenInNew />
                    }
                  ]
                : []),
              {
                name: formatMessage('options.profileMenu.logOut'),
                onClick: onLogout,
                icon: <ExitToApp />
              }
            ]}
          />
        )
      }
    ],
    [formatMessage, isAdmin, onLogout, userBccId, userEmail, userInitials, userName]
  )

  return useMemo(
    () => [
      {
        title: formatMessage('title'),
        logo: <FaviconEmail viewBox="0 0 56 56" />,
        options,
        currentPath: '/',
        onNavigate() {
          return null
        }
      }
    ],
    [formatMessage, options]
  )
}
