import React, { useMemo } from 'react'

import GlobalLayout from 'global/components/features/signinSignup/layout/SigninSignupLayout'
import Wrapper from 'global/components/features/signinSignup/layout/SigninSignupWrapper'
import Header from 'global/components/features/signinSignup/layout/SigninSignupHeader'
import Content from 'global/components/features/signinSignup/layout/SigninSignupContent'
import ContentColumn from 'global/components/features/signinSignup/layout/SigninSignupContentColumn'
import Connect from 'global/components/features/signinSignup/layout/SigninSignupConnect'

export interface Props {
  error?: string | false
  children: any
}

interface SigninSignupLayoutFC<TProps> extends React.FC<TProps> {
  Wrapper: React.FC<any>
  Header: React.FC<any>
  Content: React.FC<any>
  ContentColumn: React.FC<any>
  Connect: React.FC<any>
}

const SigninSignupLayout: SigninSignupLayoutFC<Props> = ({ error, children }) => {
  return useMemo(
    () => (
      <GlobalLayout error={error} isMaintenanceBannerEnabled>
        {children}
      </GlobalLayout>
    ),
    [error, children]
  )
}

SigninSignupLayout.Wrapper = Wrapper
SigninSignupLayout.Header = Header
SigninSignupLayout.Content = Content
SigninSignupLayout.ContentColumn = ContentColumn
SigninSignupLayout.Connect = Connect

export default SigninSignupLayout
