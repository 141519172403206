import React from 'react'

import globalPrivateRoute from 'global/components/lib/routes/PrivateRoute'
import validateSession from 'global/components/lib/authLayer/validateSession'
import validateRoute from 'global/components/lib/authLayer/validateRoute'

import validateAppForAccessToken from 'dfp/lib/validateAppForAccessToken'
import routesConfig from 'dfp/lib/routes/routesConfig'

export default globalPrivateRoute((component, childProps, route) => {
  const WrappedComponent = validateSession(
    validateRoute(component, { validateAppForAccessToken, route }),
    routesConfig.SIGNIN_SIGNUP.goto
  )

  return <WrappedComponent {...childProps} routeConfig={route} />
})
