import React, { useMemo } from 'react'

import Loading from 'global/components/lib/loading/Loading'
import StartTrial from 'dfp/components/pages/startTrial/StartTrial'
// import { useErrorFormatMessage } from 'global/lib/localization'

import useDFPContentCondition, {
  UseDFPContentConditionParams,
  UIState
} from 'dfp/components/lib/useDFPContentCondition/useDFPContentCondition'
import NoPermission from 'sen/components/lib/noPermission/NoPermission'
import ContentLayout from 'sen/components/lib/layout/ContentLayout'

export type UseDFPContentConditionProps = UseDFPContentConditionParams

export interface DFPContentLoader {
  default: (props: UseDFPContentConditionProps, userEmail: string) => any
  loading?: (props: UseDFPContentConditionProps, userEmail: string) => any
  missedEntitlement?: (props: UseDFPContentConditionProps, userEmail: string) => any
  startTrial?: (props: UseDFPContentConditionProps, userEmail: string) => any
}

export default function dfpContentLoader(loaderConfig: DFPContentLoader) {
  const DFPContentLoader: React.FC<UseDFPContentConditionProps> = props => {
    // const formatErrorMessage = useErrorFormatMessage()
    const [uiState, userEmail] = useDFPContentCondition()

    return useMemo(() => {
      switch (uiState) {
        case UIState.loading:
          return loaderConfig.loading?.(props, userEmail) || <Loading data-field="loading" />
        case UIState.missedEntitlement:
          return loaderConfig.missedEntitlement?.(props, userEmail) || <NoPermission />
        case UIState.startTrial:
          return loaderConfig.startTrial?.(props, userEmail) || <StartTrial />
        default:
          return <ContentLayout data-field="content-layout">{loaderConfig.default(props, userEmail)}</ContentLayout>
      }
    }, [uiState, userEmail, props])
  }

  return DFPContentLoader
}
