import { useCallback, useMemo } from 'react'

import { createOnpremToken, setError } from 'global/redux/features/auth/authSlice'

import { useAppSelector, useAppDispatch } from 'dfp/redux/toolkit/hooks'
import gotoReportPage from 'dfp/lib/gotoReportPage'

export interface SigninConnectLogicProps {
  connectSkipCb: () => void
  error: string | undefined
}

export default function useSigninConnectLogic(): [SigninConnectLogicProps] {
  const dispatch = useAppDispatch()
  const { error, user } = useAppSelector(_stores => ({
    error: _stores.auth.error,
    user: _stores.user.data
  }))

  // Create accessToken with onPrem provider, if successful move to Domain Fraud page
  const connectSkipCb = useCallback(() => {
    dispatch(createOnpremToken({ email: user.email }))
      .unwrap()
      .then(res => {
        gotoReportPage({ user: res })
      })
      .catch(resError => setError(resError))
  }, [dispatch, user])

  return useMemo(() => {
    return [{ connectSkipCb, error }]
  }, [connectSkipCb, error])
}
