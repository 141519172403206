import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import useProductLib from 'global/lib/product/useProduct'
import { config } from 'global/lib/config'
import { useEffectOnInit } from 'global/lib/useCustomEffect'
import { useAppSelector, useAppDispatch } from 'global/redux/toolkit/hooks'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { logout } from 'global/redux/features/auth/authApiThunks'
import { addProduct, resetAddProduct } from 'global/redux/features/account/accountSlice'
import { isSuccess } from 'global/redux/toolkit/api'

export interface StartTrial {
  onAlreadyPurchased: (e: any) => void
  onStartTrial: () => void
  onUseAnotherAccount: () => void
  userEmail: string
  salesLeadEpId: number
}

export default function useStartTrialLogic(): [StartTrial] {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [productLib] = useProductLib()
  const { getProductSerialStateForAccessToken } = productLib

  const { accessToken, isAddProductSuccess, userEmail } = useAppSelector((_stores: any) => ({
    accessToken: _stores.accessToken.accessToken || {},
    isAddProductSuccess: isSuccess(_stores.account.addProductApiStatus),
    userEmail: _stores.user.data?.email
  }))

  // init
  useEffectOnInit(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.START_TRIAL_PAGEVIEW, { email: userEmail })
  }, [])

  /*
    If add product request is a success, reload the page using navigate(0).
    This is for app agnostic navigation to work.

    FIR - once the page is reloaded we go into the report logic
    SEN/DFP - once the page is reloaded we call getUser() (this uses the authCallbacks.ts getUser())
    which calls validateUser logic
   */
  useEffect(() => {
    if (isAddProductSuccess) {
      dispatch(resetAddProduct())
      navigate(0)
    }
  }, [isAddProductSuccess, dispatch, navigate])

  const product = useMemo(
    () => (config.domainConfig.isForensics ? config.PRODUCTS.FORENSICS : config.PRODUCTS.SENTINEL),
    []
  )

  // sales lead ep id
  const salesLeadEpId = useMemo(() => {
    switch (product) {
      case config.PRODUCTS.IDS.DFP:
        return config.PRODUCTS.DFP
      case config.PRODUCTS.SENTINEL:
        return config.PRODUCTS.IDS.SENTINEL
      case config.PRODUCTS.FORENSICS:
        return config.PRODUCTS.IDS.FORENSICS
      default:
        return config.PRODUCTS.IDS.SENTINEL
    }
  }, [product])

  /* Start trial
     The start trial button in the global component has a href that redirects to the PAM activation page
   */
  const onStartTrial = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.FREE_TRIAL_STARTED, {
      email: userEmail,
      page: 'start-trial',
      product
    })
  }, [product, userEmail])

  const onAlreadyPurchased = useCallback(
    (e: any) => {
      e.preventDefault()

      const productSerialState = getProductSerialStateForAccessToken(accessToken.id, product)

      /*
        If the customer has an active product license, then add the product.  If not, redirect to the
        activation page.

        This is temp code to programmatically support customers who have one product assignment but serials for both products who haven't activated both products (back end code now takes care of this in PAM V2)
       */
      if (productSerialState === 'A') {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.FREE_TRIAL_START_BOOTSTRAP, {
          accessTokenId: accessToken.id,
          product,
          email: userEmail
        })

        dispatch(
          addProduct({
            accessTokenId: accessToken.id,
            product,
            email: userEmail
          })
        )
      } else {
        window.location.href = config.emailProtectionActivateKeyUrl
      }
    },
    [accessToken.id, product, userEmail, getProductSerialStateForAccessToken, dispatch]
  )

  // use another account
  const onUseAnotherAccount = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  return useMemo(
    () => [
      {
        onAlreadyPurchased,
        onUseAnotherAccount,
        onStartTrial,
        userEmail,
        salesLeadEpId
      }
    ],
    [onAlreadyPurchased, onStartTrial, onUseAnotherAccount, salesLeadEpId, userEmail]
  )
}
