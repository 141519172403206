import React, { useEffect } from 'react'

import routesConfig from 'dfp/lib/routes/routesConfig'

const DEFAULT_PAGE = routesConfig.SIGNIN_SIGNUP

const UnregisteredPage: React.FC<any> = () => {
  useEffect(() => {
    DEFAULT_PAGE.goto()
  }, [])

  return null
}

export default UnregisteredPage
