import React, { useMemo } from 'react'

import { Button, Chip, DataTable, DataTableColumn, Typography, Paper } from '@barracuda-internal/bds-core'
import { Tooltip } from '@progress/kendo-react-tooltip'

import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage } from 'global/lib/localization'

import useClickedLinksByIncidentLogic, { UseClickedLinksByIncidentParams } from './useClickedLinksByIncidentLogic'
import styles, { clickedLinksByIncidentGridStyle } from './clickedLinksByIncidentStyles'

const BASE_I18N_KEY = 'fir.app.remediation.clicked_links_by_incident'

type ClickedLinksByIncidentProps = UseClickedLinksByIncidentParams

const ClickedLinksByIncident: React.FC<ClickedLinksByIncidentProps> = props => {
  const classes = styles()
  const gridClasses = clickedLinksByIncidentGridStyle()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [clickedLinksByIncidentLogic] = useClickedLinksByIncidentLogic(props)

  return useMemo(() => {
    const {
      columnsConfig,
      exportPath,
      GRID_COLUMNS,
      gridData,
      isPageInProgress,
      onExportData,
      pageConfig
    } = clickedLinksByIncidentLogic
    return (
      <>
        <iframe className={classes.exportIframe} src={exportPath} title="export-link-protect" />
        <Paper className={classes.wrapper} elevation={1}>
          <div className={classes.header}>
            <div className={classes.title}>
              <Typography variant="h5">{formatMessage('labels.page_title')}</Typography>
              <Typography variant="subtitle1">{formatMessage('labels.page_subtitle')}</Typography>
              <br />
              <Typography variant="subtitle1">
                {formatMessage('labels.distribution_list', {
                  b: (txt: any) => (
                    <span key={txt} className={classes.bold}>
                      {txt}
                    </span>
                  )
                })}
              </Typography>
            </div>
            <Button className={classes.exportButton} onClick={onExportData} color="primary" variant="contained">
              {formatMessage('labels.export_csv')}
            </Button>
          </div>
          {isPageInProgress && <LinearProgress />}
          <Tooltip openDelay={100} position="right" anchorElement="target">
            <DataTable className={gridClasses.gridLayout} pageable {...pageConfig} data={gridData}>
              <DataTableColumn
                field={GRID_COLUMNS.DATE_SENT}
                {...columnsConfig[GRID_COLUMNS.DATE_SENT]}
                title={formatMessage('clicked_links_table.dateReceived')}
                cell={({ dataItem }) => (
                  <Cell>
                    <CellText>{dataItem.dateSent}</CellText>
                  </Cell>
                )}
              />
              <DataTableColumn
                field={GRID_COLUMNS.RECIPIENTS}
                {...columnsConfig[GRID_COLUMNS.RECIPIENTS]}
                title={formatMessage('clicked_links_table.usersInvolved')}
                cell={({ dataItem }) => (
                  <Cell title={dataItem.recipients.join(', ')} className={classes.wordBreak}>
                    {dataItem.recipients.map((text: string) => (
                      <CellText key={text}>{text}</CellText>
                    ))}
                  </Cell>
                )}
              />
              <DataTableColumn
                field={GRID_COLUMNS.ESS_ID}
                {...columnsConfig[GRID_COLUMNS.ESS_ID]}
                title={formatMessage('clicked_links_table.messageId')}
                cell={({ dataItem }) => (
                  <Cell className={classes.wordBreak}>
                    <CellText>{dataItem.essId}</CellText>
                  </Cell>
                )}
              />
              <DataTableColumn
                field={GRID_COLUMNS.SUBJECT}
                {...columnsConfig[GRID_COLUMNS.SUBJECT]}
                title={formatMessage('clicked_links_table.subject')}
                cell={({ dataItem }) => (
                  <Cell>
                    <CellText>{dataItem.subject}</CellText>
                  </Cell>
                )}
              />
              <DataTableColumn
                field={GRID_COLUMNS.LINK}
                {...columnsConfig[GRID_COLUMNS.LINK]}
                title={formatMessage('clicked_links_table.clickedLink')}
                cell={({ dataItem }) => (
                  <Cell title={dataItem.url} className={classes.wordBreak}>
                    <CellText>
                      {(dataItem.contentType || dataItem.mimeType) && (
                        <Chip label={dataItem.contentType || dataItem.mimeType} size="small" />
                      )}
                    </CellText>
                    <CellText>{dataItem.url || formatMessage('clicked_links_table.noClicks')}</CellText>
                  </Cell>
                )}
              />
              <DataTableColumn
                field={GRID_COLUMNS.CLICKED_IPS}
                {...columnsConfig[GRID_COLUMNS.CLICKED_IPS]}
                title={formatMessage('clicked_links_table.clickedIps')}
                cell={({ dataItem }) => (
                  <Cell className={classes.wordBreak}>
                    {dataItem.clickedIp.map((text: string) => (
                      <CellText className={classes.clickedIps} key={text}>
                        {text}
                      </CellText>
                    ))}
                  </Cell>
                )}
              />
              <DataTableColumn
                field={GRID_COLUMNS.USER_AGENTS}
                {...columnsConfig[GRID_COLUMNS.USER_AGENTS]}
                title={formatMessage('clicked_links_table.userAgents')}
                cell={({ dataItem }) => (
                  <Cell>
                    <ul>
                      {dataItem.userAgents.map((text: string) => (
                        <li key={text}>{text}</li>
                      ))}
                    </ul>
                  </Cell>
                )}
              />
            </DataTable>
          </Tooltip>
        </Paper>
      </>
    )
  }, [classes, clickedLinksByIncidentLogic, formatMessage, gridClasses.gridLayout])
}

export default ClickedLinksByIncident
