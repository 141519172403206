import React from 'react'

import GlobalEmptyReport from 'global/components/features/emptyReport/EmptyReport'

import routesConfig from 'dfp/lib/routes/routesConfig'

export default () => (
  <GlobalEmptyReport
    handler={accessTokenId => {
      routesConfig.DOMAIN_FRAUD.goto({ reportId: accessTokenId })
    }}
  />
)
