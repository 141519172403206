// Remove entire file as part of BNCASB-5258
import createDmarcSourcesStore from 'sen/redux/features/dataTables/dmarcSources/createDmarcSourcesStore'

export const dmarcHighVolumesSourcesStore = createDmarcSourcesStore('ALL_SOURCES')

export {
  INITIAL_STATE,
  GRID_COLUMNS,
  COLUMNS_CONFIG
} from 'sen/redux/features/dataTables/dmarcSources/createDmarcSourcesStore'
export const { update, reset } = dmarcHighVolumesSourcesStore.actions
export default dmarcHighVolumesSourcesStore.reducer
