// Remove entire file as part of BNCASB-5258
import createDmarcSourcesStore from 'sen/redux/features/dataTables/dmarcSources/createDmarcSourcesStore'

export const dmarcHighVolumesSourcesStore = createDmarcSourcesStore('ALL_REJECTED')

export {
  INITIAL_STATE,
  COLUMNS_CONFIG,
  GRID_COLUMNS
} from 'sen/redux/features/dataTables/dmarcSources/createDmarcSourcesStore'
export const { update, reset } = dmarcHighVolumesSourcesStore.actions
export default dmarcHighVolumesSourcesStore.reducer
