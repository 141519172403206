import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Card, Typography, Popover } from '@barracuda-internal/bds-core'

import { config } from 'global/lib/config'
import styles from 'global/components/features/signinSignup/permissionDialog/permissionDialogStyles'
import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'app.signin_signup.permission_dialog'

export interface Props {
  title: string
  appName: string
  onClose: () => void
  rootClass?: any
  anchorEl: Element
}

const PermissionDialog: React.FC<Props> = ({ title, appName, onClose, rootClass, anchorEl }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <Popover open anchorEl={anchorEl}>
        <Card data-dialog="permission" className={rootClass || classes.root} elevation={2}>
          <div className={classes.arrowUp} />
          <div data-field="permission-close" className={classes.close} onClick={onClose} role="presentation">
            X
          </div>
          <Typography className={classes.title} align="left">
            {title}
          </Typography>
          <Typography className={classes.subtitle} align="left">
            {formatMessage('subtitle', { appName })}
          </Typography>
          <Typography className={classes.mainText} align="left">
            {formatMessage('list_main_text', { appName })}
          </Typography>
          <div>
            {!config.domainConfig.isDfp
              ? Array.from(Array(4), (_, i) => i + 1).map(index => (
                  <Typography className={classes.listItemText} key={index} align="left">
                    {formatMessage(`list_item_${index}`, { appName })}
                  </Typography>
                ))
              : Array.from(Array(3), (_, i) => i + 1).map(index => (
                  <Typography className={classes.listItemText} key={index} align="left">
                    {formatMessage(`dfp_list_item_${index}`, { appName })}
                  </Typography>
                ))}
          </div>
        </Card>
      </Popover>
    ),
    [title, appName, onClose, rootClass, classes, formatMessage, anchorEl]
  )
}

PermissionDialog.propTypes = {
  title: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  rootClass: PropTypes.any
}

PermissionDialog.defaultProps = {
  rootClass: null
}

export default PermissionDialog
