import globalO365SignupConnect from 'global/components/features/signinSignup/o365Connect/o365Connect'
import { config } from 'global/lib/config'
import { reduxStore } from 'global/lib/reduxStore'
import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'

import routesConfig from 'dfp/lib/routes/routesConfig'
import { setUser } from 'dfp/redux/features/user/userSlice'

export default function connect(location: any, isComplete = false, accountId?: string) {
  globalO365SignupConnect({
    scanType: config.SCAN_TYPES.DFP,
    location,
    isComplete,
    accountId,
    onSigninComplete: data => {
      reduxStore.dispatch(setUser(data.user))
      reduxStore.dispatch(setCurrentAccessToken(data.accessToken.id))

      routesConfig.SIGNUP_COMPLETE.goto({ user: data.user, reportId: data.accessToken.id })
    }
  })
}
