import { makeStyles, createStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'
import commonStyles from 'global/components/features/signinSignup/styles/commonStyles'
import selectLabelStyles from 'global/components/lib/select/selectLabelStyles'

const WRAPPER_WIDTH = 800
const MAX_WIDTH = 350

export const signinConnectStyles = createStyles({
  wrapper: {
    display: 'flex',
    height: 'auto',
    margin: '90px auto auto auto',
    justifyContent: 'center',
    alignItems: 'top',
    fontFamily: '"Roboto", sans-serif',
    overflow: 'visible',
    maxWidth: WRAPPER_WIDTH,
    flexDirection: 'column',
    padding: '30px 50px'
  },
  title: {
    ...commonStyles.title,
    fontSize: 18,
    fontWeight: 600,
    margin: '10px auto 20px'
  },
  subtitle1: {
    fontSize: 14,
    fontWeight: 600
  },
  subtitle2: {
    color: COLORS.DARK_GRAY,
    fontSize: 14,
    fontWeight: 400
  },
  blueText: {
    ...commonStyles.blueText
  },
  o365Button: {
    ...commonStyles.o365Button,
    maxWidth: MAX_WIDTH,
    margin: '30px auto 10px'
  },
  skipConnect: {
    fontSize: 12,
    fontWeight: 400,
    color: COLORS.BLUE,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  formO365Icon: {
    ...commonStyles.o365Icon
  },
  footerWrapper: {
    marginTop: 10
  },
  footer1: {
    fontSize: 12,
    fontWeight: 400,
    color: COLORS.DARK_GRAY
  },
  footer2: {
    fontSize: 12,
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: COLORS.BLUE
  },
  selectText: {
    ...commonStyles.inputTitle,
    ...selectLabelStyles.wrappedLabel,
    fontWeight: 600
  },
  selectWrapper: {
    alignSelf: 'center',
    marginTop: 30,
    width: MAX_WIDTH
  },
  menuItem: {
    width: MAX_WIDTH
  }
})

export default makeStyles(() => signinConnectStyles)
