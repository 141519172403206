// Todo: Remove entire file for BNCASB-5258
import { useEffect, useCallback, useReducer, useMemo } from 'react'

import validator from 'validator'

import { isPending, isSuccess } from 'global/redux/toolkit/api'
import { resetError, reset, domainFraudSignup } from 'global/redux/features/auth/authSlice'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { config } from 'global/lib/config'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import routesConfig from 'sen/lib/routes/routesConfig'

export interface UseDomainFraudSignupLogic {
  isLoading: boolean
  onSignup: () => void
  error: string | false
}

export interface FormValues {
  [key: string]: string | undefined
  name: string
  company: string
  country: string
  email: string
  password: string
}

export type IsLoading = boolean
export type FormErrors = {
  [key: string]: boolean
  hasEmptyFields: boolean
  isInvalidEmail: boolean
  isInvalidPassword: boolean
}
export interface Errors {
  signup: string | undefined
  form: FormErrors
}
export interface Form {
  values: FormValues
  setFormValues: (formValue: Partial<FormValues>) => void
}
export interface FormHandlers {
  onSignup: () => void
  onGotoSignin: () => void
  onGotoPrivacyPolicy: () => void
}
export interface FormStates {
  isSignupButtonDisabled: boolean
}

let storedFormValues: FormValues | undefined

export default function useDomainFraudSignupLogic(): [IsLoading, Form, FormHandlers, FormStates, Errors] {
  const [formValues, setFormValues] = useReducer(
    (_state: FormValues, newState: Partial<FormValues>) => ({ ..._state, ...newState }),
    {
      name: '',
      company: '',
      country: 'US',
      email: '',
      password: ''
    }
  )
  const { inProgress, isSuccessSignup, authError } = useAppSelector(_stores => ({
    inProgress: isPending(_stores.auth.domainFraudApiStatus),
    isSuccessSignup: isSuccess(_stores.auth.domainFraudApiStatus),
    authError: _stores.auth.error
  }))
  const dispatch = useAppDispatch()

  // init
  useEffect(() => {
    dispatch(reset())
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_DOMAIND_FRAUD)

    if (storedFormValues) {
      setFormValues(storedFormValues)
      storedFormValues = undefined
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isSuccessSignup) {
      routesConfig.SIGNUP_PROFILE.goto()
    }
  }, [isSuccessSignup])

  // signup flow
  const onSignup = useCallback(() => {
    dispatch(resetError())
    dispatch(domainFraudSignup({ ...formValues }))
  }, [dispatch, formValues])

  const formErrors: FormErrors = useMemo(() => {
    return {
      hasEmptyFields: Object.keys(formValues).some(formValue => !formValues[formValue]?.length),
      isInvalidEmail: !!formValues.email.length && !validator.isEmail(formValues.email),
      isInvalidPassword: !!formValues.password.length && !config.VALIDATORS.PASSWORD.test(formValues.password)
    }
  }, [formValues])

  const isSignupButtonDisabled = useMemo(() => {
    return Object.keys(formErrors).some(errorKey => formErrors[errorKey]) || inProgress
  }, [formErrors, inProgress])

  return useMemo(() => {
    return [
      inProgress,
      { values: formValues, setFormValues },
      {
        onSignup,
        onGotoSignin: routesConfig.SIGNIN_SIGNUP.goto,
        onGotoPrivacyPolicy: () => {
          storedFormValues = formValues
          routesConfig.SIGNUP_POLICY.goto({}, true)
        }
      },
      { isSignupButtonDisabled },
      { signup: authError, form: formErrors }
    ]
  }, [inProgress, onSignup, authError, formValues, setFormValues, formErrors, isSignupButtonDisabled])
}
