import React from 'react'
import { createRoot } from 'react-dom/client'
import deepmerge from 'deepmerge'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'

import { BDSProvider } from '@barracuda-internal/bds-core'

import * as bdsTheme from '@barracuda-internal/bds-core/dist/styles/themes/baseMui'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import '@progress/kendo-theme-material/dist/all.css'

import theme from 'global/theme'
import browserHistory from 'global/lib/routes/browserHistory'
import { CustomBrowserRouter } from 'global/lib/routes/CustomBrowserRouter'
import initStore from 'global/lib/reduxStore'
import initLocalization, { Localization } from 'global/lib/localization'

import appReducers from 'dfp/redux/reducers'
import MESSAGES_EN from 'dfp/configs/i18n/en'
import Routes from 'dfp/components/lib/routes/Routes'

const root = createRoot(document.getElementById('root')!)

export function DfpApp(): void {
  const i18nConfig: Localization = initLocalization(MESSAGES_EN)
  const appTheme = deepmerge(bdsTheme.default, theme)

  return root.render(
    <Provider store={initStore(appReducers)}>
      <IntlProvider locale={i18nConfig.locale} messages={i18nConfig.messages}>
        <BDSProvider useBdsTheme>
          <MuiThemeProvider theme={createTheme(appTheme)}>
            <CssBaseline />
            <CustomBrowserRouter history={browserHistory}>{Routes}</CustomBrowserRouter>
          </MuiThemeProvider>
        </BDSProvider>
      </IntlProvider>
    </Provider>
  )
}
