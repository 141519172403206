import globalMessages, {
  BARRACUDA_EMAIL_PROTECTION,
  DFP_APP_NAME,
  LINKING_CODE_INVALID,
  MICROSOFT_365,
  SERIAL_NUMBER_INVALID,
  SIGN_IN,
  START_TRIAL
} from 'global/lib/i18n/en'

export const PERMISSION_DIALOG_TITLE = `Why are so many permissions needed to run ${DFP_APP_NAME}?`

export default {
  ...globalMessages,
  dfp: {
    navbar: {
      title: DFP_APP_NAME,
      options: {
        apps: 'Your apps',
        profile: 'Profile',
        profileMenu: {
          manageAccount: 'Manage account',
          logOut: 'Log out'
        }
      }
    },
    login: {
      barracuda_product: DFP_APP_NAME,
      product_blurb: 'Stop brand impersonation attacks on your customers and business partners',
      signup_description: `${DFP_APP_NAME} protects your brands from spoofing and impersonation, simplifies email authentication, and improves deliverability of your marketing messages`,
      form: {
        already_using: `Already using ${DFP_APP_NAME}? <signin>${SIGN_IN}</signin>`,
        permission_dialog_title: PERMISSION_DIALOG_TITLE
      }
    },
    signin_connect: {
      app_name: DFP_APP_NAME,
      title: `Connect to ${MICROSOFT_365} to import your domains into ${DFP_APP_NAME}`,
      permission_dialog_title: PERMISSION_DIALOG_TITLE,
      subtitle_1: `Secure your account in real-time with ${DFP_APP_NAME}. You must have an ${MICROSOFT_365} global administrator account to authorize.`,
      subtitle_2:
        'After you sign in to your Microsoft account, accept the application <perm>permissions</perm> to continue.',
      o365_button: `Connect to ${MICROSOFT_365}`,
      footer_text: 'Use another account',
      account_want_to_connect: 'Account to connect',
      skip_button: 'Continue and complete this step later'
    },
    start_trial: {
      activate: {
        activate_button: `Activate ${DFP_APP_NAME}`,
        activate_header: `Activate ${DFP_APP_NAME}`,
        linking_code: 'Linking code',
        linking_code_error: LINKING_CODE_INVALID,
        serial_number: 'Serial number',
        serial_number_error: SERIAL_NUMBER_INVALID
      },
      already_purchased: `I have already purchased ${DFP_APP_NAME}`,
      barracuda: BARRACUDA_EMAIL_PROTECTION,
      product: DFP_APP_NAME,
      subtitle: 'Protect your brands from spoofing and impersonation.',
      product_description:
        'Use Barracuda DMARC reporting to protect your reputation and stop domain fraud that targets your customers and business partners.',
      try_free: `Try <b>${DFP_APP_NAME}</b> trial for FREE for 14-days.`,
      no_credit_card: 'No credit card is required.',
      start_trial: START_TRIAL,
      use_another_account: 'Use another account'
    }
  }
}
