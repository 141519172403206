import { useMemo } from 'react'

// import { config } from 'global/lib/config'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import useProductLib from 'global/lib/product/useProduct'
import { getErrorMessage } from 'global/redux/toolkit/api'

import { useAppSelector } from 'dfp/redux/toolkit/hooks'

export type UserEmail = string
export type ApiError = string | undefined

export interface UseDFPContentConditionParams {
  location: Location
}

export enum UIState {
  'loading',
  'missedEntitlement',
  'renderContent',
  'startTrial'
}

export default function useDFPContentCondition(): [UIState, UserEmail, ApiError] {
  const { accessTokenId, scanStatusError, userEmail } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    // TODO: remove this as part of BNCASB-5258
    scanStatusError: getErrorMessage(_stores.scan?.scanStatusApiStatus) || '',
    // End todo
    userEmail: _stores.user.data?.email
  }))

  // const dispatch = useAppDispatch()
  const [accessTokenLib] = useAccessTokenLib()
  const [productLib] = useProductLib()

  // We may not need this, but we will need to check for product assignment
  // const isDPBundle = useMemo(() => {
  //   const bundleVersion = productLib.getDFPSerialBundleForAccessToken(accessTokenId)
  //   return bundleVersion === config.BUNDLES.DP
  // }, [productLib, accessTokenId])

  const hasDFPEntitlement = useMemo(() => {
    return accessTokenLib.hasDFPEntitlement(accessTokenId)
  }, [accessTokenId, accessTokenLib])

  const hasDFPProduct = useMemo(() => {
    return productLib.hasDFPProduct(accessTokenId)
  }, [productLib, accessTokenId])

  // TODO: remove this as part of BNCASB-5258
  const apiError: ApiError = useMemo(() => {
    return scanStatusError
  }, [scanStatusError])
  // End todo

  const uiState: UIState = useMemo(() => {
    switch (true) {
      case !hasDFPProduct:
        return UIState.startTrial
      case !hasDFPEntitlement:
        return UIState.missedEntitlement
      default:
        return UIState.renderContent
    }
  }, [hasDFPEntitlement, hasDFPProduct])

  return useMemo(() => {
    return [uiState, userEmail || '', apiError]
  }, [uiState, userEmail, apiError])
}
