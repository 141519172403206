const APP = {
  NAME: 'dfp',
  LANGUAGE: 'en',
  LINKS: {
    PRIVACY_POLICY: 'https://www.barracuda.com/support/security',
    REQUEST_A_DEMO: 'https://www.barracuda.com/products/email-protection/domain-fraud-protection'
  }
}

export default APP
