import accessTokenLib from 'global/lib/accessToken/accessToken'
import { reduxStore } from 'global/lib/reduxStore'
import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import { getUser, logout, setError } from 'global/redux/features/auth/authSlice'
import { config } from 'global/lib/config'
import { User } from 'global/types/api/userType'

import routesConfig from 'dfp/lib/routes/routesConfig'
import { setUser } from 'dfp/redux/features/user/userSlice'

export interface GotoReportPageParams {
  user?: User
  accessTokenId?: string
}

export default function gotoReportPage({ user, accessTokenId }: GotoReportPageParams): void {
  if (user) reduxStore.dispatch(setUser(user))

  const validatedAccessTokenId =
    accessTokenId ||
    accessTokenLib.getDefaultDfpAccessTokenId() ||
    accessTokenLib.getDefaultBccAccountAccessTokenId(user?.defaultAccountBccId, config.PRODUCTS.DFP) ||
    accessTokenLib.getAllAccessTokens()[0]?.id

  if (validatedAccessTokenId) {
    reduxStore
      .dispatch(setCurrentAccessToken(validatedAccessTokenId))
      .unwrap()
      .then(() => {
        routesConfig.DOMAIN_FRAUD.goto({ reportId: validatedAccessTokenId })
      })
      .catch(() => {
        reduxStore.dispatch(logout())
        reduxStore.dispatch(setError('no_permissions'))
      })
  } else {
    /*
      If the user doesn't have an access token, call the global getUser() in authSlice

      This will call the validateUser() which will determine if we should go to start trial
      or connect O365
     */
    reduxStore.dispatch(getUser())
  }
}
