import { config } from 'global/lib/config'

const { BUNDLES } = config
const DP_ACCESS = [BUNDLES.DP, BUNDLES.BUNDLE2]

const UI_ROUTES = {
  DOMAIN_FRAUD: {
    path: '/report/:reportId/domain-fraud',
    metadata: {
      isReportRoute: true,
      bundle: DP_ACCESS
    }
  },
  DOMAIN_FRAUD_DMARC_REVIEW_WITH_UNKNOWN_TAB: {
    path: '/report/:reportId/domain-fraud/dmarc-review/:domain/unknown',
    metadata: {
      isReportRoute: true,
      accountSwitchTargetId: 'DOMAIN_FRAUD',
      sideMenu: 'DOMAIN_FRAUD',
      bundle: DP_ACCESS
    }
  },
  DOMAIN_FRAUD_DMARC_REVIEW_WITH_APPROVED_TAB: {
    path: '/report/:reportId/domain-fraud/dmarc-review/:domain/approved',
    metadata: {
      isReportRoute: true,
      accountSwitchTargetId: 'DOMAIN_FRAUD',
      sideMenu: 'DOMAIN_FRAUD',
      bundle: DP_ACCESS
    }
  },
  DOMAIN_FRAUD_DMARC_REVIEW_WITH_REJECTED_TAB: {
    path: '/report/:reportId/domain-fraud/dmarc-review/:domain/rejected',
    metadata: {
      isReportRoute: true,
      accountSwitchTargetId: 'DOMAIN_FRAUD',
      sideMenu: 'DOMAIN_FRAUD',
      bundle: DP_ACCESS
    }
  },
  EMPTY_REPORT: {
    path: '/report'
  },
  SIGNIN_SIGNUP: {
    path: '/signup'
  },
  SIGNIN_CONNECT: {
    path: '/signin/connect'
  },
  SIGNUP_COMPLETE: {
    path: '/signin/complete'
  },
  START_TRIAL: {
    path: '/start-trial'
  }
}

export default UI_ROUTES
