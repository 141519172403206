import { Action } from '@reduxjs/toolkit'

import { User } from 'global/types/api/userType'
import { RootState } from 'global/redux/toolkit/store'
import { UiRoutes, UiRoute } from 'global/lib/routes/routesConfig'
import { logout, setError, updateUserState } from 'global/redux/features/auth/authSlice'
import userDataLib from 'global/lib/userData/userData'
import accessTokenLib from 'global/lib/accessToken/accessToken'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { config } from 'global/lib/config'
import { reduxStore } from 'global/lib/reduxStore'
import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'

/*
  These states are considered valid because we need to still allow the user into the UI during the
  grace period of 30 days (serial state is 'C')
 */
export const validSerialStates = ['A', 'C']

export interface GotoReportPage {
  user?: User
  scan?: any
  accessTokenId: string
}

export type ValidateUserParams = {
  user: User
  accessTokenId?: string
  isSignupUser?: boolean
  isSigninAction?: boolean
}

export type ValidateUserEnvironmentParams = {
  routesConfig: UiRoutes
  setUser: (user: User) => Action
  gotoReportPage: (params: GotoReportPage) => void
}

export function validateOnPremUser(user: User, routesConfig: UiRoutes): boolean | string {
  const { pathname } = window.location

  function gotoPath(route: UiRoute, params = {}) {
    if (pathname !== route.path) {
      route.goto(params)
    }

    return route.id
  }

  const firstAccessToken = accessTokenLib.getAllAccessTokens()[0]

  // If onprem user
  if (user && userDataLib.isOnPremAccessToken(firstAccessToken)) {
    switch (true) {
      // the signup is not completed yet
      case [config.USER_STATES.PROFILE, config.USER_STATES.PROFILE_O365_CONNECTED].some(state => user.state === state):
        return gotoPath(routesConfig.SIGNUP_PROFILE)
      // completed profile, transition to v1 /domain-fraud
      case user.state === config.USER_STATES.PROFILE_COMPLETED:
        reduxStore.dispatch(setCurrentAccessToken(firstAccessToken.id))

        return gotoPath(routesConfig.DOMAIN_FRAUD, { reportId: firstAccessToken?.id })
      default:
        return true
    }
  }

  // not onprem user
  return false
}

export default function validateUser(params: ValidateUserParams, environmentParams: ValidateUserEnvironmentParams) {
  const { user, accessTokenId, isSignupUser = false, isSigninAction = false } = params
  const { routesConfig, setUser, gotoReportPage } = environmentParams

  reduxStore.dispatch(setUser(user))

  const {
    app: { activePath },
    auth: { NEEDS_TO_CONNECT_O365_STATES, NEEDS_TO_CONNECT_REMEDIATES_STATES }
  } = reduxStore.getState() as RootState
  const isAdminPage = activePath.url.includes('/admin')
  const isSharedPage = activePath.url.includes('/shared')

  // set the user for intercom and mixpanel
  if (user) {
    analyticsLib.setUser(user)
  }

  // if shared link
  if (isSharedPage) {
    return
  }

  // If the session is invalid
  if (!user) {
    // and user is trying to reach a PrivateRoute
    if (!activePath.isPublicRoute) {
      routesConfig.SIGNIN_SIGNUP.goto()
    }

    // otherwise break the doUpdateStores flow
    return
  }

  // if MFA auth needs
  if (user?.mfa && !user?.isMFAuthenticated) {
    routesConfig.SIGNIN_OTP.goto()
    return
  }

  // if onPrem user
  if (validateOnPremUser(user, routesConfig)) {
    return
  }

  // Validate user state when has account(s)
  const { accounts } = user

  if (accounts?.length) {
    const accessTokenFromBCC = accessTokenLib.getDefaultBccAccountAccessTokenId(
      user.defaultAccountBccId,
      config.PRODUCTS.SENTINEL
    )

    // Need to figure out how to determine if we should send them to start trial (speak with Ryan about activation)
    if (
      config.domainConfig.isDfp &&
      !accounts.some((account: any) => validSerialStates.includes(account?.bccSerialNumbers?.dfp?.state))
    ) {
      routesConfig.START_TRIAL.goto()
      return
    }

    // If the app is sentinel and the user has no valid serials, redirect to start trial
    if (
      config.domainConfig.isSentinel &&
      !accounts.some((account: any) => validSerialStates.includes(account?.bccSerialNumbers?.sentinel?.state))
    ) {
      routesConfig.START_TRIAL.goto()
      return
    }

    // Handle the different non-complete user states
    if (
      (config.domainConfig.isSentinel || config.domainConfig.dfp) &&
      accessTokenFromBCC &&
      user.state === config.USER_STATES.PROFILE
    ) {
      reduxStore.dispatch(
        updateUserState({
          accessTokenId: accessTokenFromBCC,
          expectedState: config.USER_STATES.PROFILE
        })
      )
    } else if (NEEDS_TO_CONNECT_O365_STATES.includes(user.state)) {
      routesConfig.SIGNIN_CONNECT.goto()
      return
    }
    if (NEEDS_TO_CONNECT_REMEDIATES_STATES.includes(user.state)) {
      routesConfig.SIGNUP_REMEDIATES.goto()
      return
    }
  }

  const firstAccessTokenId = accessTokenLib.getAllAccessTokens()[0]?.id
  const currentAccessToken = userDataLib.getAccessTokenById(accessTokenId || firstAccessTokenId)
  const accessTokenIdfromUrl = accessTokenLib.getAccessTokenFromUrl()

  if (config.domainConfig.isEts) {
    const currentUserAdminFlag = currentAccessToken
      ? userDataLib.getAccountByAccessToken(currentAccessToken.id)?.userAdminFlag
      : (accounts || [{}])[0].userAdminFlag

    if (!currentUserAdminFlag && !userDataLib.isImpersonationMode() && !isAdminPage) {
      reduxStore.dispatch(logout())
      reduxStore.dispatch(setError('no_permissions'))
    }
  }

  // when the accessTokenId is not belonging to the user but has other accesstokens
  if (!currentAccessToken && accessTokenId && firstAccessTokenId) {
    gotoReportPage({ accessTokenId: firstAccessTokenId })
    return
  }

  // if the accessToken is valid
  if (currentAccessToken) {
    reduxStore.dispatch(setCurrentAccessToken(currentAccessToken.id))
    // user navigates to empty report page but has accessTokens
    // or if redirected from impersonation mode
    if (!isSignupUser && !accessTokenIdfromUrl && !isSigninAction && !isAdminPage) {
      gotoReportPage({ accessTokenId: currentAccessToken.id })
    }

    // A user with no reports won't have an accessToken but is still valid
  } else if (accounts && !isSigninAction) {
    routesConfig.EMPTY_REPORT.goto()
  } else {
    reduxStore.dispatch(logout())
    reduxStore.dispatch(setError('unable_to_load_scan'))
  }
}
