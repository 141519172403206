import { useEffect, useCallback, useReducer, useMemo } from 'react'

import { useAppSelector, useAppDispatch } from 'global/redux/toolkit/hooks'
import { config } from 'global/lib/config'
import { logout } from 'global/redux/features/auth/authSlice'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { Account } from 'global/types/api/accountType'

export interface UseSigninConnectLogicProps {
  allowSkip?: boolean
  connectSkipCb?: () => void
  appName: any
  location: any
  routesConfig: any
  o365Connect: any
}

export interface SigninConnectLogicProps {
  allowSkip?: boolean
  appName: string
  onConnect: () => void
  onSkipConnect: () => void
  requestDemoPath: string
  onTogglePermissionDialog: () => void
  isPermissionDialogVisible: boolean
  loggedInUser?: string
  onUseAnotherAccount: () => void
  sendDemoRequestTrackingEvent: () => void
  accounts?: Account[]
  multipleAccountConfig: {
    isActive: boolean
    accounts: Account[]
    onAccountChange: (e: any) => void
    selectedAccountId: string | undefined
  }
}

export interface State {
  code: string
}

export default function useSigninConnectLogic({
  allowSkip,
  appName,
  connectSkipCb,
  location,
  o365Connect,
  routesConfig
}: UseSigninConnectLogicProps): [SigninConnectLogicProps] {
  const { accounts, user, NEEDS_TO_CONNECT_O365_STATES } = useAppSelector((_stores: any) => ({
    NEEDS_TO_CONNECT_O365_STATES: _stores.auth.NEEDS_TO_CONNECT_O365_STATES,
    user: _stores.user.data,
    accounts: _stores.user.data.accounts
  }))
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    isPermissionDialogVisible: false,
    selectedAccountId: accounts?.[0]?.accountId
  })
  const dispatch = useAppDispatch()

  // init
  useEffect(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNIN_CONNECT_PAGEVIEW, {
      url: window.location.href,
      email: user.email
    })
    if (
      !user.id ||
      (user.id &&
        !NEEDS_TO_CONNECT_O365_STATES.includes(user.state) &&
        user.state !== config.USER_STATES.PROFILE_COMPLETED)
    ) {
      routesConfig.SIGNIN_SIGNUP.goto()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onTogglePermissionDialog = useCallback(() => {
    setState({ isPermissionDialogVisible: !state.isPermissionDialogVisible })
    // we only want to send the tracking once, when the user clicks
    if (!state.isPermissionDialogVisible) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_PERMISSION, { url: window.location.href })
    }
  }, [state.isPermissionDialogVisible, setState])

  const onConnect = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNIN_CONNECT_O365, {
      url: window.location.href,
      accoundId: state.selectedAccountId,
      email: user.email
    })
    o365Connect(location, true, state.selectedAccountId)
  }, [location, o365Connect, state.selectedAccountId, user.email])

  // Allows users to skip the O365 connect step, the connectSkipCb should be a product specific function
  const onSkipConnect = useCallback(() => {
    if (connectSkipCb && allowSkip) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.O365_CONNECT_SKIPPED, {
        url: window.location.href,
        accoundId: state.selectedAccountId,
        email: user.email
      })

      connectSkipCb()
    }
  }, [allowSkip, connectSkipCb, state.selectedAccountId, user.email])

  const onUseAnotherAccount = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  const sendDemoRequestTrackingEvent = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SIGNUP_REQUEST_DEMO, { url: window.location.href })
  }, [])

  const multipleAccountConfig = useMemo(() => {
    return {
      isActive: accounts?.length > 1,
      accounts,
      selectedAccountId: state.selectedAccountId,
      onAccountChange: (e: any) => {
        setState({ selectedAccountId: e.target.value })
      }
    }
  }, [accounts, state.selectedAccountId])

  return useMemo(() => {
    return [
      {
        allowSkip,
        appName,
        onConnect,
        onSkipConnect,
        requestDemoPath: config.LINKS.REQUEST_A_DEMO,
        onTogglePermissionDialog,
        isPermissionDialogVisible: state.isPermissionDialogVisible,
        loggedInUser: user.email,
        onUseAnotherAccount,
        sendDemoRequestTrackingEvent,
        multipleAccountConfig
      }
    ]
  }, [
    allowSkip,
    appName,
    onConnect,
    onSkipConnect,
    onTogglePermissionDialog,
    state.isPermissionDialogVisible,
    user.email,
    onUseAnotherAccount,
    sendDemoRequestTrackingEvent,
    multipleAccountConfig
  ])
}
