import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { reset as resetDataTables } from 'global/redux/features/dataTables/dataTablesSlice'

import { DataTableState } from 'sen/redux/types/dataTables'
import * as alerts from 'sen/redux/features/dataTables/alerts/alertsSlice'
import * as allowedSenders from 'sen/redux/features/dataTables/allowedSenders/allowedSendersSlice'
import * as attacksByDomain from 'sen/redux/features/dataTables/attacksByDomain/attacksByDomainSlice'
import * as attacksByIdentity from 'sen/redux/features/dataTables/attacksByIdentity/attacksByIdentitySlice'
import * as attacksBySubject from 'sen/redux/features/dataTables/attacksBySubject/attacksBySubjectSlice'
// TODO: Remove as part of BNCASB-5258
import * as rufDmarcSamples from 'sen/redux/features/dataTables/dmarcSamples/ruf'
import * as dmarcFraudUnifiedData from 'sen/redux/features/dataTables/dmarcFraudUnifiedData/dmarcFraudUnifiedDataSlice'
import * as dmarcFraudUnifiedRufData from 'sen/redux/features/dataTables/dmarcFraudUnifiedRufData/dmarcFraudUnifiedRufDataSlice'
import * as dmarcAllApproved from 'sen/redux/features/dataTables/dmarcSources/dmarcAllApproved'
import * as dmarcAllRejected from 'sen/redux/features/dataTables/dmarcSources/dmarcAllRejected'
import * as dmarcHighVolumesSources from 'sen/redux/features/dataTables/dmarcSources/dmarcHighVolumeSources'
import * as dmarcIpsFailed from 'sen/redux/features/dataTables/dmarcIps/failedIps'
import * as dmarcIpsPassed from 'sen/redux/features/dataTables/dmarcIps/passedIps'
import * as dmarcLowVolumeSources from 'sen/redux/features/dataTables/dmarcSources/dmarcLowVolumeSources'
import * as dmarcFailedSubdomains from 'sen/redux/features/dataTables/dmarcSubdomains/failedSubdomains'
import * as dmarcPassedSubdomains from 'sen/redux/features/dataTables/dmarcSubdomains/passedSubdomains'
// END TODO
import * as domains from 'sen/redux/features/dataTables/domains/domainsSlice'
import * as filterAttacks from 'sen/redux/features/dataTables/filterAttacks/filterAttacksSlice'
import * as inboxRules from 'sen/redux/features/dataTables/inboxRules/inboxRulesSlice'
import * as inboxRuleFeed from 'sen/redux/features/dataTables/inboxRuleFeed/inboxRuleFeedSlice'
import * as incidents from 'sen/redux/features/dataTables/incidents/incidentsSlice'
import * as incidentsEmails from 'sen/redux/features/dataTables/incidents/incidentsEmailsSlice'
import * as incidentRecipients from 'sen/redux/features/dataTables/incident/recipientsSlice'
import * as incidentRecipientsWizard from 'sen/redux/features/dataTables/incident/recipientsWizardSlice'
import * as mailboxes from 'sen/redux/features/dataTables/mailboxes/mailboxesSlice'
import * as signinsByAllCountries from 'sen/redux/features/dataTables/signinsByAllCountries/signinsByAllCountriesSlice'
import * as signinsByCountry from 'sen/redux/features/dataTables/signinsByCountry/signinsByCountrySlice'
import * as signinsByUser from 'sen/redux/features/dataTables/signinsByUser/signinsByUser'
import * as spAttacks from 'sen/redux/features/dataTables/spAttacks/spAttacksSlice'
import * as alertThreats from 'sen/redux/features/dataTables/alert/threatsSlice'
import * as alertSigninsSlice from 'sen/redux/features/dataTables/alert/signinsSlice'
import * as alertInboxRulesSlice from 'sen/redux/features/dataTables/alert/inboxRulesSlice'
import * as unifiedReportingSlice from 'sen/redux/features/dataTables/unifiedReporting/unifiedReportingSlice'

export interface DataTablesState {
  alert: {
    inboxRules: DataTableState
    signins: DataTableState
    threats: DataTableState
  }
  alerts: DataTableState
  allowedSenders: DataTableState
  attacksByDomain: DataTableState
  attacksByIdentity: DataTableState
  attacksBySubject: DataTableState
  // TODO: Remove as part of BNCASB-5258
  dmarcFraudUnifiedData: DataTableState
  dmarcFraudUnifiedRufData: DataTableState
  dmarcIps: {
    failed: DataTableState
    passed: DataTableState
  }
  dmarcSamples: {
    ruf: DataTableState
  }
  dmarcSources: {
    allApproved: DataTableState
    allRejected: DataTableState
    highVolume: DataTableState
    lowVolume: DataTableState
  }
  dmarcSubdomains: {
    failed: DataTableState
    passed: DataTableState
  }
  // End TODO
  domains: DataTableState
  filterAttacks: DataTableState
  inboxRules: DataTableState
  newInboxRules: DataTableState
  previousInboxRules: DataTableState
  inboxRuleFeed: DataTableState
  incidents: DataTableState
  incidentsEmails: DataTableState
  incident: {
    recipients: DataTableState
    recipientsWizard: DataTableState
  }
  mailboxes: DataTableState
  signinsByAllCountries: DataTableState
  signinsByCountry: DataTableState
  signinsByUser: DataTableState
  spAttacks: DataTableState
  unifiedReporting: unifiedReportingSlice.UnifiedReportingState
}

// initialState
export const INITIAL_STATE: DataTablesState = {
  alerts: alerts.INITIAL_STATE,
  alert: {
    inboxRules: alertInboxRulesSlice.INITIAL_STATE,
    signins: alertSigninsSlice.INITIAL_STATE,
    threats: alertThreats.INITIAL_STATE
  },
  allowedSenders: allowedSenders.INITIAL_STATE,
  attacksByDomain: attacksByDomain.INITIAL_STATE,
  attacksByIdentity: attacksByIdentity.INITIAL_STATE,
  attacksBySubject: attacksBySubject.INITIAL_STATE,
  dmarcFraudUnifiedData: dmarcFraudUnifiedData.INITIAL_STATE,
  dmarcFraudUnifiedRufData: dmarcFraudUnifiedRufData.INITIAL_STATE,
  dmarcIps: {
    failed: dmarcIpsFailed.INITIAL_STATE,
    passed: dmarcIpsPassed.INITIAL_STATE
  },
  // Remove as part of BNCASB-5258
  dmarcSamples: {
    ruf: rufDmarcSamples.INITIAL_STATE
  },
  dmarcSources: {
    allApproved: dmarcAllApproved.INITIAL_STATE,
    allRejected: dmarcAllRejected.INITIAL_STATE,
    highVolume: dmarcHighVolumesSources.INITIAL_STATE,
    lowVolume: dmarcLowVolumeSources.INITIAL_STATE
  },
  dmarcSubdomains: {
    failed: dmarcFailedSubdomains.INITIAL_STATE,
    passed: dmarcPassedSubdomains.INITIAL_STATE
  },
  // End TODO
  domains: domains.INITIAL_STATE,
  filterAttacks: filterAttacks.INITIAL_STATE,
  inboxRules: inboxRules.INITIAL_STATE,
  newInboxRules: inboxRules.RANGE_INITITAL_STATE,
  previousInboxRules: inboxRules.RANGE_INITITAL_STATE,
  inboxRuleFeed: inboxRuleFeed.INITIAL_STATE,
  incidents: incidents.INITIAL_STATE,
  incidentsEmails: incidentsEmails.INITIAL_STATE,
  incident: {
    recipients: incidentRecipients.INITIAL_STATE,
    recipientsWizard: incidentRecipientsWizard.INITIAL_STATE
  },
  mailboxes: mailboxes.INITIAL_STATE,
  signinsByAllCountries: signinsByAllCountries.INITIAL_STATE,
  signinsByCountry: signinsByCountry.INITIAL_STATE,
  signinsByUser: signinsByUser.INITIAL_STATE,
  spAttacks: spAttacks.INITIAL_STATE,
  unifiedReporting: unifiedReportingSlice.INITIAL_STATE
}

/* eslint-disable no-param-reassign */
export const dataTablesSlice = createSlice({
  name: 'DATA_TABLES',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(resetDataTables.toString(), () => {
      return {
        ...INITIAL_STATE
      }
    })
    builder
      .addMatcher(isAnyOf(alerts.update, alerts.reset), (state, action) => {
        state.alerts = { ...alerts.default(state.alerts, action) }
      })
      .addMatcher(isAnyOf(alertInboxRulesSlice.update, alertInboxRulesSlice.reset), (state, action) => {
        state.alert.inboxRules = { ...alertInboxRulesSlice.default(state.alert.inboxRules, action) }
      })
      .addMatcher(isAnyOf(alertSigninsSlice.update, alertSigninsSlice.reset), (state, action) => {
        state.alert.signins = { ...alertSigninsSlice.default(state.alert.signins, action) }
      })
      .addMatcher(isAnyOf(alertThreats.update, alertThreats.reset), (state, action) => {
        state.alert.threats = { ...alertThreats.default(state.alert.threats, action) }
      })
      .addMatcher(isAnyOf(allowedSenders.update, allowedSenders.reset), (state, action) => {
        state.allowedSenders = { ...allowedSenders.default(state.allowedSenders, action) }
      })
      .addMatcher(isAnyOf(attacksByDomain.update, attacksByDomain.reset), (state, action) => {
        state.attacksByDomain = { ...attacksByDomain.default(state.attacksByDomain, action) }
      })
      .addMatcher(isAnyOf(attacksByIdentity.update, attacksByIdentity.reset), (state, action) => {
        state.attacksByIdentity = { ...attacksByIdentity.default(state.attacksByIdentity, action) }
      })
      .addMatcher(isAnyOf(attacksBySubject.update, attacksBySubject.reset), (state, action) => {
        state.attacksBySubject = { ...attacksBySubject.default(state.attacksBySubject, action) }
      })
      // TODO: Remove as part of BNCASB-5258
      .addMatcher(isAnyOf(dmarcFraudUnifiedData.update, dmarcFraudUnifiedData.reset), (state, action) => {
        state.dmarcFraudUnifiedData = { ...dmarcFraudUnifiedData.default(state.dmarcFraudUnifiedData, action) }
      })
      .addMatcher(isAnyOf(dmarcFraudUnifiedRufData.update, dmarcFraudUnifiedRufData.reset), (state, action) => {
        state.dmarcFraudUnifiedRufData = { ...dmarcFraudUnifiedRufData.default(state.dmarcFraudUnifiedRufData, action) }
      })
      .addMatcher(isAnyOf(dmarcIpsFailed.update, dmarcIpsFailed.reset), (state, action) => {
        state.dmarcIps.failed = { ...dmarcIpsFailed.default(state.dmarcIps.failed, action) }
      })
      .addMatcher(isAnyOf(dmarcIpsPassed.update, dmarcIpsPassed.reset), (state, action) => {
        state.dmarcIps.passed = { ...dmarcIpsPassed.default(state.dmarcIps.passed, action) }
      })
      .addMatcher(isAnyOf(rufDmarcSamples.update, rufDmarcSamples.reset), (state, action) => {
        state.dmarcSamples.ruf = { ...rufDmarcSamples.default(state.dmarcSamples.ruf, action) }
      })
      .addMatcher(isAnyOf(dmarcAllApproved.update, dmarcAllApproved.reset), (state, action) => {
        state.dmarcSources.allApproved = { ...dmarcAllApproved.default(state.dmarcSources.allApproved, action) }
      })
      .addMatcher(isAnyOf(dmarcAllRejected.update, dmarcAllRejected.reset), (state, action) => {
        state.dmarcSources.allRejected = { ...dmarcAllRejected.default(state.dmarcSources.allRejected, action) }
      })
      .addMatcher(isAnyOf(dmarcHighVolumesSources.update, dmarcHighVolumesSources.reset), (state, action) => {
        state.dmarcSources.highVolume = { ...dmarcHighVolumesSources.default(state.dmarcSources.highVolume, action) }
      })
      .addMatcher(isAnyOf(dmarcLowVolumeSources.update, dmarcLowVolumeSources.reset), (state, action) => {
        state.dmarcSources.lowVolume = { ...dmarcLowVolumeSources.default(state.dmarcSources.lowVolume, action) }
      })
      .addMatcher(isAnyOf(dmarcFailedSubdomains.update, dmarcFailedSubdomains.reset), (state, action) => {
        state.dmarcSubdomains.failed = { ...dmarcFailedSubdomains.default(state.dmarcSubdomains.failed, action) }
      })
      .addMatcher(isAnyOf(dmarcPassedSubdomains.update, dmarcPassedSubdomains.reset), (state, action) => {
        state.dmarcSubdomains.passed = { ...dmarcPassedSubdomains.default(state.dmarcSubdomains.passed, action) }
      })
      // End TODO
      .addMatcher(isAnyOf(domains.update, domains.reset), (state, action) => {
        state.domains = { ...domains.default(state.domains, action) }
      })
      .addMatcher(isAnyOf(filterAttacks.update, filterAttacks.reset), (state, action) => {
        state.filterAttacks = { ...filterAttacks.default(state.filterAttacks, action) }
      })
      .addMatcher(isAnyOf(inboxRules.inboxRulesActions.update, inboxRules.inboxRulesActions.reset), (state, action) => {
        state.inboxRules = { ...inboxRules.inboxRulesReducer(state.inboxRules, action) }
      })
      .addMatcher(
        isAnyOf(inboxRules.newInboxRulesActions.update, inboxRules.newInboxRulesActions.reset),
        (state, action) => {
          state.newInboxRules = { ...inboxRules.newInboxRulesReducer(state.inboxRules, action) }
        }
      )
      .addMatcher(
        isAnyOf(inboxRules.previousInboxRulesActions.update, inboxRules.previousInboxRulesActions.reset),
        (state, action) => {
          state.previousInboxRules = { ...inboxRules.previousInboxRulesReducer(state.inboxRules, action) }
        }
      )
      .addMatcher(isAnyOf(inboxRuleFeed.update, inboxRuleFeed.reset), (state, action) => {
        state.inboxRuleFeed = { ...inboxRuleFeed.default(state.inboxRuleFeed, action) }
      })
      .addMatcher(isAnyOf(incidents.update, incidents.reset), (state, action) => {
        state.incidents = { ...incidents.default(state.incidents, action) }
      })
      .addMatcher(isAnyOf(incidentsEmails.update, incidentsEmails.reset), (state, action) => {
        state.incidentsEmails = { ...incidentsEmails.default(state.incidentsEmails, action) }
      })
      .addMatcher(isAnyOf(incidentRecipients.update, incidentRecipients.reset), (state, action) => {
        state.incident.recipients = { ...incidentRecipients.default(state.incident.recipients, action) }
      })
      .addMatcher(isAnyOf(incidentRecipientsWizard.update, incidentRecipientsWizard.reset), (state, action) => {
        state.incident.recipientsWizard = {
          ...incidentRecipientsWizard.default(state.incident.recipientsWizard, action)
        }
      })
      .addMatcher(isAnyOf(mailboxes.update, mailboxes.reset), (state, action) => {
        state.mailboxes = { ...mailboxes.default(state.mailboxes, action) }
      })
      .addMatcher(isAnyOf(signinsByAllCountries.update, signinsByAllCountries.reset), (state, action) => {
        state.signinsByAllCountries = { ...signinsByAllCountries.default(state.signinsByAllCountries, action) }
      })
      .addMatcher(isAnyOf(signinsByCountry.update, signinsByCountry.reset), (state, action) => {
        state.signinsByCountry = { ...signinsByCountry.default(state.signinsByCountry, action) }
      })
      .addMatcher(isAnyOf(signinsByUser.update, signinsByUser.reset), (state, action) => {
        state.signinsByUser = { ...signinsByUser.default(state.signinsByUser, action) }
      })
      .addMatcher(isAnyOf(spAttacks.update, spAttacks.reset), (state, action) => {
        state.spAttacks = { ...spAttacks.default(state.spAttacks, action) }
      })
      .addMatcher(isAnyOf(unifiedReportingSlice.update, unifiedReportingSlice.reset), (state, action) => {
        state.unifiedReporting = { ...unifiedReportingSlice.default(state.unifiedReporting, action) }
      })
  }
})
/* eslint-enable no-param-reassign */

export default dataTablesSlice.reducer
