import React, { useMemo } from 'react'

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import { Typography, Button, Radio, RadioGroup, Grid, Alert } from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'

import useSpearPhishingTabLogic from 'sen/components/pages/settings/spearPhishingTab/useSpearPhishingTabLogic'
import CustomizeAlertDialog from 'sen/components/lib/dialogs/customizeAlertDialog/CustomizeAlertDialog'
import styles from 'sen/components/pages/settings/spearPhishingTab/spearPhishingTabStyles'
import settingsStyles from 'sen/components/pages/settings/settingsStyles'
import AdminModeSelector from 'sen/components/pages/settings/shared/AdminMode'

const BASE_I18N_KEY = 'sen.app.settings_dialog.spear_phishing_tab'

export const SpearPhishingTab: React.FC = () => {
  const classes = styles()
  const settingsClasses = settingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()

  const [tabConfig, quarantineModeSection, enduserModeSection, adminModeSection] = useSpearPhishingTabLogic()

  const isEmailFieldDisabled =
    adminModeSection.isDisabled || Number(adminModeSection.radioButtonsConfig.selectedRadioButton) !== 1

  return useMemo(
    () => (
      <Grid container className={settingsClasses.tabContent} direction="column">
        {enduserModeSection.isCustomizeAlertDialogOpened && (
          <CustomizeAlertDialog onClose={enduserModeSection.onToggleCustomizationDialog} />
        )}

        {tabConfig.errorMsg && (
          <Alert className={settingsClasses.error} severity="error">
            {errorFormatMessage(tabConfig.errorMsg)}
          </Alert>
        )}

        {tabConfig.isTabInProgress && <LinearProgress className={settingsClasses.loadingIndicator} />}

        <Grid container className={classes.borderedSection} data-section="quarantine-mode" direction="column">
          <FormControl>
            <FormLabel>
              <Typography>{formatMessage('section_quarantine_mode.title')}</Typography>
            </FormLabel>
            <RadioGroup
              className={classes.radioButtonGroup}
              value={quarantineModeSection.radioButtonsConfig.selectedRadioButton}
              onChange={quarantineModeSection.radioButtonsConfig.onSelectRadioButton}
            >
              {quarantineModeSection.QUARANTINE_VALUES.map((key, index) => (
                <Grid item xs={12} key={String(key)}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label={formatMessage(`section_quarantine_mode.select_${index + 1}`)}
                    disabled={quarantineModeSection.isDisabled}
                    value={String(key)}
                  />
                </Grid>
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid container className={classes.borderedSection} data-section="enduser-mode" direction="column">
          <RadioGroup
            className={classes.radioButtonGroup}
            value={enduserModeSection.radioButtonsConfig.selectedRadioButton}
            onChange={enduserModeSection.radioButtonsConfig.onSelectRadioButton}
          >
            {enduserModeSection.ENDUSER_VALUES.map((key, index) => (
              <Grid item xs={12} key={String(key)}>
                <FormControlLabel
                  control={<Radio color="primary" />}
                  label={formatMessage(`section_enduser_mode.select_${index + 1}`, {
                    link: (text: string) => {
                      return (
                        <Button
                          key={text}
                          disabled={
                            enduserModeSection.isDisabled ||
                            Number(enduserModeSection.radioButtonsConfig.selectedRadioButton) !== key
                          }
                          onClick={enduserModeSection.onToggleCustomizationDialog}
                          variant="text"
                          color="primary"
                        >
                          {text}
                        </Button>
                      )
                    }
                  })}
                  disabled={enduserModeSection.isDisabled}
                  value={String(key)}
                />
              </Grid>
            ))}
          </RadioGroup>
        </Grid>
        <Grid container className={classes.lastSection} data-section="admin-mode" direction="column">
          <AdminModeSelector
            BASE_I18N_KEY={BASE_I18N_KEY}
            isTabInProgress={tabConfig.isTabInProgress}
            {...adminModeSection}
          />
        </Grid>
        <Grid container className={settingsClasses.buttonsContainer} alignItems="center" justifyContent="flex-end">
          <Button
            className={settingsClasses.formButton}
            onClick={tabConfig.onSaveChanges}
            disabled={tabConfig.isSaveButtonDisabled || (!adminModeSection.isValidAdminEmail && !isEmailFieldDisabled)}
            color="primary"
            variant="contained"
            size="large"
          >
            {formatMessage('save')}
          </Button>
        </Grid>
      </Grid>
    ),
    [
      classes,
      settingsClasses,
      formatMessage,
      errorFormatMessage,
      tabConfig,
      quarantineModeSection,
      enduserModeSection,
      adminModeSection,
      isEmailFieldDisabled
    ]
  )
}

export default SpearPhishingTab
