import deepmerge from 'deepmerge'

import { makeStyles } from '@material-ui/core/styles'

import { makeGridStyles } from 'global/components/lib/grid/gridStyles'

export default makeStyles(theme => ({
  bold: {
    fontWeight: theme.font.weight.bold
  },
  exportButton: {
    float: 'right',
    marginTop: theme.spacing(1.5)
  },
  exportIframe: {
    display: 'none'
  },
  header: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    position: 'relative',
    zIndex: 1
  },
  title: {
    display: 'inline-block'
  },
  wordBreak: {
    wordBreak: 'break-all'
  },
  clickedIps: {
    display: 'block'
  },
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    margin: theme.spacing(2, 2, 3, 2),
    overflow: 'hidden',
    position: 'relative'
  }
}))

export const gridStyle = {
  gridLayout: {
    '& .k-grid-header col:nth-of-type(6)': {
      width: '12% !important'
    },
    '& .k-grid-table col:nth-of-type(6)': {
      width: '12% !important'
    }
  }
}

export const clickedLinksByIncidentGridStyle = makeStyles(theme => ({
  ...deepmerge(makeGridStyles(theme), gridStyle)
}))
